@charset "utf-8";

#kpi {
  .library-title {
    margin-bottom: 30px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .kpi-nav {
    margin-bottom: 60px;

    @include mq() {
      margin-bottom: 50px;
    }
  }

  .kpi-title {
    margin-bottom: 10px;

    .large,
    .small {
      display: block;
    }

    .large {
      font-size: 2rem;
      font-weight: bold;

      @include mq() {
        font-size: 1.8rem;
      }
    }

    .small {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .kpi-note {
    margin-top: 25px;
    font-size: 1.2rem;
    color: $gray-666;

    @include mq() {
      margin-top: 20px;
    }

    .note-text {
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}
