@charset "utf-8";

#faq {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-information/faq/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-information/faq/heading_bg_sp.jpg);
    }
  }

  .l-column {
    .container {
      max-width: 1040px;
    }
  }

  .section {
    padding-top: 20px;
    padding-bottom: 60px;

    @include mq() {
      padding-bottom: 30px;
    }
  }

  .faq-title {
    margin-bottom: 30px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .faq-nav {
    margin-bottom: 60px;

    @include mq() {
      margin-bottom: 30px;
    }

    .nav-list {
      background-color: $gray-f5f5f5;

      @include mq(medium, min, true) {
        display: flex;
        padding: 20px;
        flex-wrap: wrap;
        justify-content: center;
      }

      .nav-item {
        @include mq(medium, min, true) {
          margin: 0 20px;
        }

        @include mq() {
          padding: 15px;
          border-bottom: 1px solid #fff;
        }

        .c-textlink {
          a {
            font-weight: normal;

            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .faq-button {
    max-width: 300px;
    margin: 30px auto 0;

    @include mq() {
      margin-top: 20px;
    }

    .c-button {
      a {
        span {
          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .faq-list {
    .faq-item {
      + .faq-item {
        margin-top: 10px;
      }

      .faq-detail {
        .detail-title,
        .detail-text {
          position: relative;
          font-size: 1.5rem;

          &:before {
            position: absolute;
            top: 16px;
            left: 20px;
            font-family: $font-barlow;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1;
            vertical-align: middle;
          }
        }

        .detail-title {
          padding: 14px 50px 14px;
          font-weight: 500;
          cursor: pointer;
          border: 1px solid $gray-dedede;

          @include mq(medium, min, true) {
            @include ease(color);
          }

          @include mq() {
            padding-right: 40px;
            padding-left: 45px;
          }

          &:before {
            content: "Q";
          }

          &:after {
            @include ease(transform);
            content: "";
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            width: 8px;
            height: 12px;
            margin: auto 0;
            transform: rotate(90deg);
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 12px;
          }

          &:hover {
            @include mq(medium, min, true) {
              color: $accent-color03;
            }
          }
        }

        .detail-text {
          margin-top: 10px;
          padding: 14px 20px 14px 50px;
          background-color: $gray-f5f5f5;

          @include mq() {
            padding-left: 45px;
          }

          &:before {
            content: "A";
          }
        }

        .js-accordionFaqCont {
          display: none;

          &.is-active {
            display: block;
          }
        }

        .js-accordionFaqBtn {
          &.is-active {
            .detail-title {
              &:after {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }
}
