@charset "utf-8";

#policy {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/policy/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/policy/heading_bg_sp.jpg);
    }
  }

  .section-title {
    margin-bottom: 45px;

    @include mq() {
      margin-bottom: 20px;
    }

    .title {
      font-size: 3rem;
      font-weight: bold;

      @include mq() {
        font-size: 2.2rem;
      }
    }
  }

  .section-text {
    margin-bottom: 50px;

    @include mq() {
      margin-bottom: 30px;
    }

    p {
      font-size: 1.5rem;
      line-height: 2;
    }
  }

  .section-list {
    @include mq(medium, min, true) {
      display: flex;
      flex-wrap: wrap;
    }

    .section-item {
      @include mq(medium, min, true) {
        width: percentage(560/1160);
      }

      &:not(:nth-child(2n)) {
        @include mq(medium, min, true) {
          margin-right: percentage(40/1160);
        }
      }

      &:nth-child(n+3) {
        @include mq(medium, min, true) {
          margin-top: 40px;
        }
      }

      + .section-item {
        @include mq() {
          margin-top: 15px;
        }
      }

      a {
        display: block;
        text-decoration: none;
        box-shadow: 0 0 20px rgba(#000, 0.08);

        .text {
          position: relative;
          padding: 30px 30px 30px 50px;
          font-size: 2rem;
          font-weight: bold;

          @include mq() {
            padding: 20px 20px 20px 40px;
            font-size: 1.8rem;
          }

          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
          }

          &:before {
            z-index: 1;
            bottom: 0;
            left: 30px;
            width: 8px;
            height: 13px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 13px;

            @include mq() {
              left: 20px;
            }
          }

          &:after {
            z-index: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }


          span {
            position: relative;
            z-index: 1;
          }
        }

        &:hover {
          .text {
            @include mq(medium, min, true) {
              color: #fff;
            }

            &:before {
              @include mq(medium, min, true) {
                background-position: center right;
              }
            }

            &:after {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }
          }

          img {
            opacity: 1;
          }
        }
      }
    }
  }
}
