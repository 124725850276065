@charset "utf-8";

#ir-message {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/policy/message/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/policy/message/heading_bg_sp.jpg);
    }
  }

  .message-block {
    padding: 50px 0 120px;

    @include mq() {
      padding: 30px 0 70px;
    }

    .container {
      max-width: 930px;
    }

    .message-image {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 30px;
      }
    }

    .message-title {
      margin-bottom: 40px;
      font-size: 3rem;
      font-weight: bold;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        margin-top: 30px;
        margin-bottom: 25px;
        font-size: 2.2rem;
      }
    }

    .message-text {
      font-size: 1.6rem;
      line-height: 2;

      @include mq() {
        font-size: 1.5rem;
      }

      p {
        + p {
          margin-top: 30px;

          @include mq() {
            margin-top: 35pxs;
          }
        }
      }

      .name {
        margin-top: 40px;

        @include mq(medium, min, true) {
          text-align: right;
        }

        @include mq() {
          margin-top: 30px;
        }

        span {
          display: inline-block;
          text-align: left;
        }
      }
    }
  }
}
