@charset "utf-8";

.c-textlink {
  a,
  span {
    position: relative;
    display: inline-block;
    padding-right: 16px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    text-decoration: none;

    svg {
      overflow: initial;
      fill: url(#gradation);

      @media all and (-ms-high-contrast: none) {
        height: 16px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 12px;
      margin: auto 0;
      background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
      background-size: auto 12px;
    }

    &:hover {
      @include mq(medium, min, true) {
        text-decoration: underline;
      }

      text {
        @include mq(medium, min, true) {
          text-decoration: underline;
        }
      }

      svg {
        @include mq(medium, min, true) {
          opacity: 1;
        }
      }
    }
  }

  &--gray {
    a {
      &:after {
        background: url(/assets/images/common/buttons/arrow.svg) no-repeat center center;
        background-size: auto 12px;
      }
    }
  }
}
