@charset "utf-8";

#ir-library {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-library/index/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-library/index/heading_bg_sp.jpg);
    }
  }

  .document-block {
    margin-bottom: 40px;

    @include mq() {
      margin-bottom: 20px;
    }

    .document-title {
      margin-bottom: 30px;

      @include mq() {
        margin-bottom: 25px;
      }
    }

    .document-text {
      margin-bottom: 28px;
      font-size: 1.5rem;
    }

    .document-box {
      padding: 30px 60px;
      background-color: $gray-f5f5f5;

      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
      }

      @include mq(large) {
        padding: 25px;
      }

      @include mq(xxsmall) {
        padding: 25px 15px;
      }

      .box-left,
      .box-right {
        @include mq(medium, min, true) {
          width: percentage(490/1040);
        }
      }

      .box-left {
        @include mq() {
          margin-bottom: 20px;
        }
      }
    }

    .document-detail {
      .detail-title {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        font-weight: bold;

        @include mq() {
          margin-bottom: 30px;
        }

        &:before {
          content: "";
          width: 80px;
          height: 80px;
          margin-right: 20px;
          background: url(/assets/images/investors/ir-library/index/icon_document.svg) no-repeat top left;
          background-size: 80px auto;
          flex-shrink: 0;

          @include mq() {
            width: 64px;
            height: 64px;
            margin-right: 15px;
            background-size: 64px auto;
          }
        }

        .large,
        .small {
          display: block;
        }

        .large {
          font-size: 2.4rem;
          font-weight: bold;

          @include mq() {
            font-size: 1.9rem;
          }
        }

        .small {
          font-size: 1.5rem;
          font-weight: normal;

          @include mq() {
            font-size: 1.2rem;
          }
        }
      }

      .detail-button {
        margin: 0 auto;

        @include mq(medium, min, true) {
          max-width: 292px;
        }
      }
    }

    .document-list {
      .document-item {
        font-size: 1.5rem;

        + .document-item {
          margin-top: 12px;

          @include mq() {
            margin-top: 25px;
          }
        }

        a {
          display: inline-block;
          padding-left: 30px;
          text-decoration: none;
          background-repeat: no-repeat;
          background-position: left top;

          @include mq(medium, min, true) {
            @include ease(color);
          }

          &.pdf {
            background-image: url(/assets/images/common/icons/pdf.svg);
            background-size: auto 24px;
          }

          &.xlsx {
            background-image: url(/assets/images/common/icons/xlsx.svg);
            background-size: auto 24px;
          }

          &.movie {
            background-image: url(/assets/images/common/icons/movie.svg);
            background-size: auto 24px;
          }

          &:hover {
            @include mq(medium, min, true) {
              text-decoration: underline;
              color: $accent-color03;
            }
          }
        }
      }
    }
  }

  .link-block {
    .link-list {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      .link-item {
        @include mq(medium, min, true) {
          width: percentage(360/1160);
        }

        &:not(:nth-child(3n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(40/1160);
          }
        }

        &:nth-child(n+4) {
          @include mq(medium, min, true) {
            margin-top: 65px;
          }
        }

        + .link-item {
          @include mq() {
            margin-top: 40px;
          }
        }
      }
    }

    .link-detail {
      > .detail-title {
        margin-bottom: 20px;
        font-size: 2rem;
        font-weight: bold;

        @include mq(large) {
          font-size: 1.8rem;
        }

        @include mq() {
          margin-bottom: 10px;
        }

        a {
          position: relative;
          display: block;
          padding: 15px 40px 15px 20px;
          text-decoration: none;
          background-color: $gray-f5f5f5;

          @include mq(medium, min, true) {
            @include ease(color);
          }

          span {
            position: relative;
            z-index: 1;
          }

          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
          }

          &:before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }

          &:after {
            right: 25px;
            bottom: 0;
            width: 8px;
            height: 12px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 12px;
          }

          &:hover {
            @include mq(medium, min, true) {
              color: #fff;
            }

            &:before {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }

            &:after {
              @include mq(medium, min, true) {
                background-position: center right;
              }
            }
          }
        }
      }

      > .detail-text,
      > .detail-link {
        padding: 0 20px;
      }

      > .detail-text {
        margin-bottom: 20px;
        font-size: 1.5rem;

        .c-newslist {
          margin-top: -20px;

          @include mq() {
            margin-top: -10px;
          }
        }
      }

      > .detail-link {
        .c-textlink {
          a {
            &.pdf,
            &.xlsx,
            &.movie {
              padding-right: 30px;

              &:after {
                width: 20px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: auto 24px;
              }
            }

            &.pdf {
              &:after {
                background-image: url(/assets/images/common/icons/pdf.svg);
              }
            }

            &.xlsx {
              &:after {
                background-image: url(/assets/images/common/icons/xlsx.svg);
              }
            }

            &.movie {
              &:after {
                background-image: url(/assets/images/common/icons/movie.svg);
              }
            }
          }
        }
      }
    }
  }
}
