@charset "utf-8";

#forecast {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/policy/forecast/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/policy/forecast/heading_bg_sp.jpg);
    }
  }

  .forecast-block {
    padding: 60px 0 130px;

    @include mq() {
      padding: 35px 0 65px;
    }

    .container {
      max-width: 1040px;
    }
  }

  .forecast-title {
    margin-bottom: 20px;

    @include mq() {
      margin-bottom: 15px;
    }

    span {
      display: inline-block;
      font-size: 1.5rem;
    }
  }

  .forecast-list {
    margin-bottom: 15px;

    .forecast-item {
      + .forecast-item {
        margin-top: 50px;
      }
    }

    .forecast-detail {
      .detail-title {
        margin-bottom: 15px;
        font-size: 2rem;
        font-weight: bold;

        @include mq() {
          margin-bottom: 10px;
          font-size: 1.8rem;
        }
      }

      .detail-text {
        margin-bottom: 30px;
        font-size: 1.5rem;
        line-height: 2;
      }

      .detail-image {
        max-width: 762px;
        margin: 0 auto;

        .image {
          cursor: pointer;
        }

        .note {
          margin-top: 10px;
          font-size: 1.3rem;
          text-align: right;
        }
      }
    }
  }

  .forecast-note {
    font-size: 1.5rem;
    line-height: 2;
    color: $gray-333;

    p {
      + p {
        margin-top: 15px;
      }
    }

    a {
      color: $accent-color03;
    }
  }
}
