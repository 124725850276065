@charset "utf-8";

#shareholder-return {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/individual-investors/shareholder-return/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/individual-investors/shareholder-return/heading_bg_sp.jpg);
    }
  }

  .l-column {
    @include mq() {
      padding-top: 40px;
    }
  }

  .section-title {
    margin-bottom: 20px;

    @include mq() {
      margin-bottom: 10px;
    }
  }

  .section-sub {
    margin-bottom: 10px;

    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  .section-text {
    margin-bottom: 45px;
    font-size: 1.5rem;
    line-height: 1.8;

    @include mq() {
      margin-bottom: 25px;
    }
  }

  .section-image {
    + .section-image {
      margin-top: 40px;

      @include mq() {
        margin-top: 25px;
      }
    }

    .image {
      cursor: pointer;
    }

    .caption {
      margin-top: 5px;
      padding-left: 1em;
      font-size: 1.2rem;
      text-indent: -1em;
      color: $gray-444;
    }
  }
}
