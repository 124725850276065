@charset "utf-8";

#corporate-information.global-innovation {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/global-innovation/heading_bg.png);

    @include mq(medium, min, true) {
      height: 327px;
    }

    @include mq() {
      background-image: url(/assets/images/corporate-information/global-innovation/heading_bg_sp.png);
    }

    .ja {
      color: #fff;
    }
  }

  // ページ共通要素
  .section {
    padding: 80px 0;

    @include mq() {
      padding: 40px 0;
    }

    &.section-bg {
      background-color: $gray-f5f5f5;
    }

    .container {
      max-width: 1040px;
    }

    .section-title {
      display: flex;
      padding-bottom: 8px;

      @include mq(medium, min, true) {
        align-items: center;
      }

      @include mq() {
        padding-bottom: 10px;
      }

      &:after {
        width: 100%;
      }

      .text {
        font-size: 2.6rem;
        line-height: 1.5;

        @include mq() {
          font-size: 2rem;
        }
      }
    }

    .section-text {
      margin-top: 30px;
      font-size: 1.6rem;
      line-height: 1.8;

      @include mq() {
        margin-top: 15px;
        font-size: 1.5rem;
        line-height: 1.7;
      }
    }

    .section-box {
      margin-top: 40px;

      @include mq(medium, min, true) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @include mq() {
        margin-top: 20px;
      }

      .box-item {
        @include mq(medium, min, true) {
          width: percentage(465/960);
        }

        + .box-item {
          @include mq() {
            margin-top: 20px;
          }
        }
      }

      &--large {
        align-items: flex-start;

        @include mq() {
          margin-top: 15px;
        }

        .box-left {
          @include mq(medium, min, true) {
            width: percentage(540/960);
          }

          .section-text {
            margin-top: 0;
          }
        }

        .box-right {
          @include mq(medium, min, true) {
            width: percentage(360/960);
          }

          @include mq() {
            margin-top: 20px;
          }
        }
      }
    }

    .section-list {
      .list-item {
        display: flex;
        font-size: 1.5rem;

        @include mq(medium, min, true) {
          align-items: center;
        }

        @include mq() {
          font-size: 1.4rem;
        }

        + .list-item {
          margin-top: 40px;

          @include mq() {
            margin-top: 20px;
          }
        }

        .square {
          margin-right: 15px;
        }

        .detail {
          .detail-title {
            font-weight: 500;
          }
        }
      }
    }

    .icon {
      flex-shrink: 0;
      width: 90px;
      margin-right: 20px;

      @include mq() {
        width: 72px;
        margin-right: 8px;
      }
    }

    .square {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 90px;
      height: 90px;
      background: linear-gradient(to right, $accent-color, $accent-color02);

      @include mq() {
        width: 72px;
        height: 72px;
      }

      span {
        font-size: 1.6rem;
        font-weight: 500;
        color: #fff;

        @include mq() {
          font-size: 1.2rem;
        }
      }
    }
  }


  //- Global Innovation
  .lead {
    padding: 60px 0 16px;

    @include mq() {
      padding-top: 40px;
    }

    .lead-text {
      margin-top: 15px;
      font-size: 1.6rem;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        margin-top: 18px;
        font-size: 1.5rem;
      }
    }
  }
}
