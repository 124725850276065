@charset "utf-8";

.l-footer {
  overflow: hidden;
  padding: 30px 0 120px;
  background-color: $base-color;

  @include mq() {
    padding: 30px 0 40px;
  }

  .footer-logo {
    max-width: 187px;
    margin-bottom: 30px;

    @include mq() {
      max-width: 150px;
      margin-bottom: 25px;
    }
  }

  .footer-sns {
    margin-bottom: 35px;

    @include mq() {
      margin-bottom: 30px;
    }

    .sns-list {
      display: flex;

      .sns-item {
        width: 100%;
        max-width: 40px;
        margin-right: 10px;
      }
    }
  }

  .footer-bottom {
    color: #fff;

    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
    }

    .link-list {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      @include mq() {
        margin-bottom: 40px;
      }

      .link-item {
        @include mq(medium, min, true) {
          margin-right: 15px;
        }

        + .link-item {
          @include mq() {
            margin-top: 25px;
          }
        }

        a {
          position: relative;
          padding-right: 16px;
          font-size: 1.3rem;
          text-decoration: none;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 8px;
            height: 12px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center center;
            background-size: auto 12px;
          }

          &:hover {
            @include mq(medium, min, true) {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .footer-copy {
      font-size: 1.2rem;

      @include mq() {
        text-align: right;
      }
    }
  }

  .footer-pagetop {
    position: absolute;
    top: 0;
    right: 40px;

    @include mq(large) {
      right: 20px;
    }

    @include mq() {
      right: 15px;
    }

    a {
      display: block;
      line-height: 0;
    }
  }
}
