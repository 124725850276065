@charset "utf-8";

#contact {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .contact-block {
    padding: 35px 0 120px;

    @include mq() {
      padding: 20px 0 60px;
    }

    &.comp {
      padding: 120px 0;

      @include mq() {
        padding: 60px 0;
      }

      .contact-text {
        margin-bottom: 40px;
        .c-title--sub {
          font-size: 2.8rem;
          text-align: center;

          @include mq() {
            font-size: 2.4rem;
          }
        }
      }

      .c-button {
        margin: 0 auto;
      }
    }

    .contact-text {
      margin-bottom: 30px;
      font-size: 1.5rem;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        margin-bottom: 20px;
      }
    }

    .contact-link {
      margin-bottom: 70px;

      @include mq() {
        margin-bottom: 90px;
      }

      .c-button-guide--large a {
        @include mq() {
          border-bottom: 1px solid $gray-dedede;
        }
      }

      .c-button {
        margin: 0 auto;
      }
    }

    .contact-attention {
      margin-bottom: 10px;
      font-size: 1.5rem;
    }

    .contact-form {
      .form-detail {
        position: relative;
        padding: 15px 0;
        border-bottom: 1px solid $gray-dedede;

        @include mq() {
          padding: 10px 0 12px;
        }

        &:after {
          @include mq(medium, min, true) {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 360px;
            height: 100%;
            background-color: $gray-f5f5f5;
          }
        }

        &:first-of-type {
          border-top: 1px solid $gray-dedede;
        }

        .detail-item {
          position: relative;
          z-index: 1;
          font-size: 1.5rem;

          @include mq(medium, min, true) {
            display: flex;
            align-items: center;
          }

          + .detail-item {
            margin-top: 15px;

            @include mq() {
              margin-top: 5px;
            }
          }
        }

        .detail-title {
          @include mq(medium, min, true) {
            width: 360px;
            padding: 0 30px;
            flex-shrink: 0;
            text-align: right;
          }

          @include mq() {
            margin-bottom: 5px;
          }

          span {
            position: relative;
            padding-right: 12px;

            .sup {
              position: absolute;
              top: -8px;
              right: -5px;
            }
          }
        }

        .detail-text {
          @include mq(medium, min, true) {
            flex: 1;
            padding: 0 30px;
          }
        }

        .top {
          align-items: flex-start;
        }
      }

      .form-certification {
        margin: 30px auto 0;
        text-align: center;

        @include mq() {
          margin-top: 20px;
        }

        @include mq(xxsmall) {
          transform: scale(0.96);
          transform-origin: center left;
        }

        .g-recaptcha {
          display: inline-block;
          text-align: left;
        }
      }

      .form-agreement {
        margin-top: 20px;
        font-size: 1.5rem;
        text-align: center;

        @include mq(xsmall) {
          font-size: 1.3rem;
        }

        .c-check {
          input {
            + .text {
              padding-right: 15px;
            }
          }
        }

        .note {
          margin-top: 10px;
          font-size: 1.3rem;

          @include mq(xsmall) {
            font-size: 1.1rem;
          }
        }
      }

      .form-button {
        margin: 60px auto 0;

        @include mq(medium, min, true) {
          max-width: 450px;
        }

        @include mq() {
          margin-top: 40px;
        }
      }
    }

    .contact-box {
      max-width: 900px;
      margin: 80px auto 0;
      padding: 25px 30px 30px;
      border: 1px solid $gray-dedede;

      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
      }

      @include mq() {
        margin-top: 60px;
        padding: 20px 15px;
      }

      .box-left {
        @include mq(medium, min, true) {
          width: calc(45% - 10px);
        }
      }

      .box-right {
        @include mq(medium, min, true) {
          width: calc(55% - 10px);
        }

        @include mq() {
          margin-top: 15px;
        }
      }

      .box-title {
        margin-bottom: 10px;
        font-size: 1.8rem;
      }

      .box-text {
        font-size: 1.5rem;

        .note {
          margin: 10px 0;
          font-size: 1.2rem;
          color: $gray-444;

          &-item {
            padding-left: 1em;
            text-indent: -1em;
          }
        }
      }
    }

    .sup {
      font-size: 180%;
      font-style: normal;
      color: $accent-color;
    }

    .error-message {
      font-size: 80%;
      color: $accent-color;
    }
  }
}
