@charset "utf-8";

#corporate-information.vision {
  .c-vision-title {
    font-weight: bold;
    line-height: 1;
    text-align: center;

    .en {
      display: inline-block;
      font-family: $font-barlow;
      font-size: 4.8rem;

      @include mq() {
        transform: scale(0.65);
      }

      svg {
        overflow: initial;
        font-family: inherit;
        fill: url(#gradation);

        @media all and (-ms-high-contrast: none) {
          height: 48px;
        }
      }
    }

    .ja {
      display: block;
      font-size: 1.8rem;

      @include mq(medium, min, true) {
        margin-top: 8px;
      }

      @include mq() {
        font-size: 1.6rem;
      }
    }
  }

  .c-vision-detail {
    .detail-title {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1.5;

      @include mq() {
        font-size: 2rem;
      }
    }

    .detail-text {
      margin-top: 30px;
      font-size: 1.6rem;

      @include mq() {
        margin-top: 15px;
        font-size: 1.5rem;
        line-height: 2;
      }
    }
  }


  //- 存在意義
  .purpose {
    padding: 65px 0 95px;
    background: url(/assets/images/corporate-information/vision/purpose_bg_top.png) no-repeat top left,
      url(/assets/images/corporate-information/vision/purpose_bg_bottom.png) no-repeat bottom right;
    background-size: percentage(887/1680) auto, percentage(1094/1680) auto;

    @include mq() {
      padding: 45px 0 95px;
      background: url(/assets/images/corporate-information/vision/purpose_bg_top_sp.png) no-repeat top left,
        url(/assets/images/corporate-information/vision/purpose_bg_bottom_sp.png) no-repeat bottom right;
      background-size: 100% auto;
    }

    .purpose-title {
      margin-top: 100px;

      @include mq() {
        margin-top: 60px;
      }
    }

    .purpose-image {
      max-width: 700px;
      margin: 30px auto 0;
    }

    .purpose-detail {
      margin-top: 30px;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        margin-top: 25px;
      }

      .detail-title {
        @include mq() {
          text-align: center;
        }
      }
    }
  }


  //- ビジョン
  .vision {
    padding: 80px 0 170px;
    background-color: $gray-f5f5f5;

    @include mq() {
      padding: 50px 0 0;
    }

    .container {
      max-width: 1040px;

      @include mq() {
        padding-bottom: vw(464);
      }

      &:before {
        content: "";
        position: absolute;
        width: percentage(505/1040);
        height: percentage(480/300);
        background: url(/assets/images/corporate-information/vision/vision_bg.png) no-repeat center center;
        background-size: 100% auto;

        @include mq(medium, min, true) {
          top: -30px;
          left: percentage(720/960);
          @include mq(mega) {
            left: percentage(600/960);
          }
        }

        @include mq() {
          right: 0;
          bottom: 0;
          width: vw(504);
          height: vw(480);
        }
      }
    }

    .vision-detail {
      position: relative;
      max-width: 620px;
      margin-top: 60px;

      @include mq() {
        margin-top: 30px;
      }
    }
  }


  //- BEENOSの由来
  .origin {
    padding: 80px 0;

    @include mq() {
      padding: 40px 0 60px;
    }

    .container {
      max-width: 1040px;
    }

    .origin-box {
      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
      }

      .origin-box-image {
        @include mq(medium, min, true) {
          width: 340px;
          flex-shrink: 0;
        }
      }

      .origin-box-detail {
        @include mq(medium, min, true) {
          margin-left: 60px;
        }

        @include mq() {
          margin-top: 15px;
        }

        .detail-title {
          font-size: 2rem;
          font-weight: bold;

          @include mq() {
            font-size: 1.8rem;
          }
        }

        .detail-text {
          margin-top: 10px;
          font-size: 1.6rem;

          @include mq() {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
