@charset "utf-8";

.c-check {
  input {
    display: none;

    + .text {
      position: relative;
      display: inline-block;
      padding-left: 35px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
        border-radius: 2px;
      }

      &:before {
        border: 2px solid $gray-bfbfbf;
        background-color: #fff;
      }

      &:after {
        opacity: 0;
        background: url(/assets/images/common/icons/check.svg) no-repeat center center;
        background-size: auto 12px;
      }
    }

    &:checked {
      + .text {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
