@charset "utf-8";

#disclaimer {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .disclaimer-block {
    padding: 30px 0 125px;

    @include mq() {
      padding: 15px 0 65px;
    }

    .container {
      max-width: 1040px;
    }

    .disclaimer-text {
      font-size: 1.5rem;
      line-height: 2;

      p {
        + p {
          margin-top: 30px;
        }
      }
    }
  }
}
