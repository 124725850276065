@charset "utf-8";

#fstrategy {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/policy/fstrategy/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/policy/fstrategy/heading_bg_sp.jpg);
    }
  }

  .l-column {
    @include mq(medium, min, true) {
      padding-top: 55px;
    }
  }

  .section {
    padding-top: 0;

    .container {
      max-width: 1040px;
    }
  }

  .section-title {
    margin-bottom: 50px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .section-sub {
    margin-bottom: 35px;

    @include mq() {
      margin-bottom: 20px;
    }

    .title {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.5;

      @include mq() {
        font-size: 2rem;
      }
    }
  }

  .section-text {
    margin-bottom: 30px;

    @include mq() {
      margin-bottom: 15px;
    }

    p {
      font-size: 1.5rem;
      line-height: 2;
    }
  }

  .section-list {
    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
    }

    .list-item {
      @include mq(medium, min, true) {
        width: percentage(460/960);
      }

      + .list-item {
        @include mq() {
          margin-top: 15px;
        }
      }
    }
  }

  .about-block {
    .about-detail {
      @include mq() {
        margin-top: 30px;
      }

      .detail-title {
        margin-bottom: 15px;
        font-size: 2rem;
        font-weight: bold;
      }

      .detail-image {
        .image {
          cursor: pointer;
        }

        .note {
          margin-top: 10px;
          font-size: 1.3rem;
          text-align: right;
        }
      }
    }
  }

  .incubation-block {
    padding-bottom: 0;
  }
}

html[lang="ja"] {
  #fstrategy .section-text p {
    word-break: break-all;
  }
}
