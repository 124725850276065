@charset "utf-8";

// Breakpoints
// ------------------------------------------------------------------------------
$mq-breakpoints: (
  "mega": 87.5em, // 1400px
  "xlarge": 74.9375em, // 1199px
  "large": 64em, // 1024px
  "medium": 48em, // 768px
  "small": 33.9375em, // 543px
  "xsmall": 23.375em, // 374px
  "xxsmall": 20em // 320px
);

// Easings
// ------------------------------------------------------------------------------
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1, 0, 0, 1);

$authenticMotion: cubic-bezier(0.4, 0, 0.2, 1);

// Fonts
// ------------------------------------------------------------------------------
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Noto+Sans+JP:wght@400;500;700&display=swap");

$base-font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3",
  "Hiragino Kaku Gothic Pro", Osaka, "游ゴシック", "Yu Gothic", YuGothic,
  "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-barlow: "Barlow", Arial, sans-serif;

// Colors
// ------------------------------------------------------------------------------
$base-color: #222;
$base-text-color: $base-color;
$accent-color: #e84527;
$accent-color02: #962025;
$accent-color03: #c12925;

// Gray
$gray-d1d1d1: #d1d1d1;
$gray-f5f5f5: #f5f5f5;
$gray-dedede: #dedede;
$gray-bfbfbf: #bfbfbf;
$gray-efefef: #efefef;
$gray-333: #333;
$gray-444: #444;
$gray-666: #666;
$gray-4c4c4c: #4c4c4c;
$gray-808080: #808080;
