@charset "utf-8";

#analyst {
  .l-column {
    .col2-main {
      @include mq() {
        margin-bottom: 40px;
      }
    }
  }

  .c-table--type02 {
    .table-text {
      .title {
        @include mq() {
          min-width: 150px;
        }
      }
    }
  }

  .library-text {
    margin-bottom: 35px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .analyst-note {
    margin-top: 20px;
    font-size: 1.2rem;
    text-align: right;

    @include mq() {
      margin-top: 10px;
    }
  }
}
