@charset "utf-8";

#balance-sheet {
  .highlights-category {
    @include mq() {
      margin-bottom: 40px;
    }
  }

  .highlights-title {
    @include mq(medium, min, true) {
      margin-bottom: 25px;
    }
  }
}
