@charset "utf-8";

.c-radio {
  .label {
    margin-right: 40px;
  }

  .text {
    position: relative;
    padding-left: 30px;
    line-height: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &:before {
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid $gray-dedede;
      background-color: $gray-f5f5f5;
    }

    &:after {
      left: 5px;
      width: 10px;
      height: 10px;
      opacity: 0;
      background-color: $accent-color;
    }
  }

  input {
    display: none;

    &:checked {
      + .text {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
