@charset "utf-8";

#corporate-information.csr {
  .csr-block {
    padding: 60px 0 120px;

    @include mq() {
      padding: 40px 0 30px;
    }

    .csr-title {
      margin-bottom: 15px;

      @include mq() {
        margin-bottom: 20px;
      }
    }

    .csr-text {
      margin-bottom: 50px;
      font-size: 1.5rem;

      @include mq() {
        margin-bottom: 40px;
      }

      .container {
        max-width: 1040px;
      }
    }

    .csr-list {
      .csr-item {
        padding: 40px 0;
        border-bottom: 1px solid $gray-dedede;

        @include mq() {
          padding: 30px 0;
        }

        &:first-child {
          border-top: 1px solid $gray-dedede;
        }

        .container {
          @include mq(medium, min, true) {
            display: flex;
          }

          .csr-image {
            @include mq(medium, min, true) {
              width: percentage(360/1160);
              flex-shrink: 0;
              margin-right: percentage(80/1160);
            }

            @include mq() {
              margin-bottom: 15px;
            }
          }

          .csr-detail {
            .detail-title {
              margin-bottom: 10px;
              font-size: 2.8rem;
              font-weight: bold;
              line-height: 1.5;

              @include mq() {
                font-size: 2.2rem;
              }
            }

            .detail-text {
              font-size: 1.5rem;

              @include mq(medium, min, true) {
                line-height: 2;
              }

              .note {
                margin-top: 10px;
                font-size: 1.2rem;
              }
            }

            .detail-button {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
}
