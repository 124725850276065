@charset "utf-8";

#corporate-information.executive {
  .executive-members {
    padding: 100px 0 120px;

    @include mq() {
      padding: 50px 0 70px;
    }

    .executive-members-box {
      + .executive-members-box {
        margin-top: 70px;

        @include mq() {
          margin-top: 60px;
        }
      }

      .c-heading-underline {
        margin-bottom: 40px;

        @include mq() {
          margin: 0 -15px;
          text-align: center;
        }

        &:before {
          display: none;
        }

        &:after {
          width: 100%;
        }
      }
    }

    .executive-members-list {
      display: flex;
      margin-top: 25px;
      margin-bottom: -40px;
      flex-wrap: wrap;

      @include mq() {
        margin-bottom: -30px;
      }

      .executive-members-item {
        width: percentage(340/1160);
        margin-bottom: 40px;
        margin-left: percentage(70/1160);

        @include mq(xlarge) {
          width: percentage(560/1160);
          margin-left: percentage(40/1160);
        }

        @include mq(large) {
          width: percentage(570/1160);
          margin-left: percentage(20/1160);
        }

        @include mq() {
          display: flex;
          width: 100%;
          margin-bottom: 30px;
          margin-left: 0;
        }

        &:nth-child(3n+1) {
          @include mq(xlarge, min, true) {
            margin-left: 0;
          }
        }

        &:nth-child(2n+1) {
          @include mq(xlarge) {
            @include mq(medium, min, true) {
              margin-left: 0;
            }
          }
        }

        .image {
          @include mq(medium, min, true) {
            margin-bottom: 30px;
          }

          @include mq() {
            width: 100px;
            margin-right: 15px;
            flex-shrink: 0;
          }
        }

        .text-wrapper {
          position: relative;
          padding-right: 63px;

          @include mq() {
            padding-right: 50px;
            flex: 1;
          }
        }

        .position {
          margin-bottom: 3px;

          @include mq() {
            font-size: 1.2rem;
          }
        }

        .name {
          font-size: 2rem;
          font-weight: bold;

          @include mq(medium, min, true) {
            margin-bottom: 3px;
          }

          @include mq() {
            font-size: 1.6rem;
          }
        }

        .name-en {
          font-size: 1.6rem;
          font-weight: 600;
          color: $accent-color03;

          @include mq() {
            font-size: 1.3rem;
          }
        }

        .modal-button {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          width: 48px;
          height: 56px;
          cursor: pointer;
          transition: opacity 0.2s ease;
          background: url(/assets/images/corporate-information/executive/button-modal.png) no-repeat 0 0;
          background-size: 48px auto;

          @include mq() {
            width: 38px;
            height: 44px;
            background-size: 38px auto;
          }

          &:hover {
            @include mq(medium, min, true) {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .executive-modal {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      background-color: #fff;

      @include mq() {
        overflow-y: scroll;
      }

      .modal-close {
        position: absolute;
        z-index: 1;
        top: 40px;
        right: 80px;
        width: 48px;
        height: 56px;
        cursor: pointer;
        transition: opacity 0.2s ease;
        background: url(/assets/images/corporate-information/executive/button-modal-close.png) no-repeat 0 0;
        background-size: 48px auto;

        @include mq(large) {
          right: 40px;
        }

        @include mq() {
          position: fixed;
          top: 20px;
          right: 20px;
          width: 38px;
          height: 44px;
          background-size: 38px auto;
        }

        &:hover {
          @include mq(medium, min, true) {
            opacity: 0.7;
          }
        }
      }

      .executive-modal-inner {
        .executive-modal-image {
          position: fixed;
          z-index: 1;
          top: 0;
          left: 0;
          width: percentage(700/1680);
          height: 100vh;
          background-repeat: no-repeat;
          background-position: 50% 0;
          background-size: cover;

          @include mq() {
            position: static;
            width: 100%;
            height: 0;
            padding-top: percentage(496/375);
          }
        }

        .executive-modal-text-wrapper {
          position: relative;
          overflow-y: scroll;
          width: percentage(980/1680);
          height: 100vh;
          margin-left: auto;
          padding: 0 94px;
          letter-spacing: -0.4em;

          @include mq(large) {
            padding: 0 40px;
          }

          @include mq() {
            width: 100%;
            height: auto;
            margin-left: 0;
            padding: 0 20px;
          }

          &::before {
            content: '';
            display: inline-block;
            width: 0;
            height: 100%;
            vertical-align: middle;
            background-color: orange;

            @include mq() {
              display: none;
            }
          }

          > * {
            letter-spacing: normal;
          }

          .executive-modal-text-inner {
            display: inline-block;
            width: 100%;
            padding: 84px 0;
            vertical-align: middle;

            @include mq() {
              padding: 50px 0;
            }

            .position {
              margin-bottom: 6px;
              font-size: 1.6rem;

              @include mq() {
                font-size: 1.4rem;
              }
            }

            .name {
              margin-bottom: 2px;
              font-size: 2.8rem;
              font-weight: bold;

              @include mq() {
                font-size: 2.6rem;
              }

              &.en {
                margin-bottom: 25px;
              }
            }

            .name-en {
              margin-bottom: 25px;
              font-size: 1.8rem;
              font-weight: 600;
              color: $accent-color03;
            }

            .text {
              font-size: 1.6rem;
              line-height: 2;

              @include mq() {
                font-size: 1.5rem;
              }
            }
          }

          .executive-modal-pager {
            display: flex;
            margin-top: 100px;
            padding-top: 30px;
            font-size: 2rem;
            font-weight: bold;
            user-select: none;
            border-top: 1px solid #dedede;
            justify-content: space-between;

            @include mq() {
              margin-top: 60px;
              font-size: 1.8rem;
            }

            .executive-modal-pager-item {
              padding-top: 20px;
              cursor: pointer;
              transition: opacity 0.2s ease;
              background-repeat: no-repeat;
              background-size: auto 12px;

              &:hover {
                @include mq(medium, min, true) {
                  opacity: 0.7;
                }
              }

              &--prev {
                padding-left: 24px;
                background-image: url(/assets/images/corporate-information/executive/label-modal-prev.png);
                background-position: 0 0;
              }

              &--next {
                padding-right: 24px;
                text-align: right;
                background-image: url(/assets/images/corporate-information/executive/label-modal-next.png);
                background-position: 100% 0;
              }
            }
          }
        }
      }
    }
  }
}