@charset "utf-8";

#disclosure {
  .l-column {
    @include mq() {
      padding-bottom: 70px;
    }
  }

  .section {
    &:last-child {
      padding-bottom: 0;
    }
  }
}
