@charset "utf-8";

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

html,
body {
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 62.5%;

  &.is-hidden {
    @include mq() {
      overflow: hidden;
    }
  }
}

body {
  padding-right: 0!important;
  font-family: $base-font-family;
  font-size: 1.4rem;
  line-height: 1.714;
  word-wrap: break-word;
  color: $base-text-color;
  -webkit-font-smoothing: antialiased;
  background-color: $base-color;

  @include mq() {
    min-width: 320px;
    font-size: 1.2rem;
  }

  ::selection {
    color: #fff;
    fill: #fff;
    background-color: $accent-color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

p,
dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: left;
}

dd {
  margin: 0;
}

iframe {
  border: 0;
}

figure {
  margin: 0;
  padding: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  color: inherit;

  img,
  svg {
    @include mq(medium, min, true) {
      @include ease(opacity);
    }
  }

  &:hover {
    @include mq(medium, min, true) {
      text-decoration: none;
    }

    img,
    svg {
      @include mq(medium, min, true) {
        opacity: 0.7;
      }
    }
  }
}

input,
select,
button,
textarea {
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  outline: none;
  appearance: none;
}

input,
textarea {
  &::placeholder {
    color: $gray-bfbfbf;
  }
}

button {
  cursor: pointer;
}

sup {
  font-size: 110%;
  line-height: 1;
  vertical-align: bottom;
  color: $accent-color;
}
