@charset "utf-8";

.l-faq {
  background: url(/assets/images/investors/faq_bg.jpg) no-repeat center center;
  background-size: cover;

  @include mq() {
    background-image: url(/assets/images/investors/faq_bg_sp.jpg);
  }

  .faq-box {
    @include mq(medium, min, true) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .box-left {
      @include mq(medium, min, true) {
        width: percentage(320/1160);
      }
    }

    .box-right {
      @include mq(medium, min, true) {
        width: percentage(730/1160);
      }

      @include mq() {
        margin-top: 25px;
      }
    }
  }

  .c-title {
    color: #fff;

    @include mq() {
      padding: 0 20px;
    }

    .ja {
      font-size: 3.2rem;

      @include mq() {
        font-size: 2.8rem;
      }
    }
  }

  .faq-button {
    margin-top: 60px;

    @include mq() {
      margin-top: 20px;
    }

    .c-button {
      @include mq() {
        margin: 0 auto;
      }
    }
  }

  .faq-list {
    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
    }

    .faq-item {
      @include mq(medium, min, true) {
        width: percentage(352/730);
      }

      + .faq-item {
        @include mq() {
          margin-top: 15px;
        }
      }
    }
  }
}
