@charset "utf-8";

.library-cont {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-library/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-library/heading_bg_sp.jpg);
    }
  }

  .c-newslist {
    margin-bottom: 25px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .library-text {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }

  .library-category {
    display: flex;
    flex-wrap: wrap;
    margin-top: -7px;

    .category-item {
      margin: 7px 0;
      line-height: 1;

      &:not(:last-child) {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid $gray-bfbfbf;
      }

      a {
        text-decoration: none;
        color: $gray-bfbfbf;

        &:hover {
          @include mq(medium, min, true) {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .library-select {
    margin-bottom: 20px;
    text-align: right;
  }

  .library-link {
    margin-bottom: 20px;
  }

  .library-movie {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-bottom: 40px;
    padding-top: 56.25%;

    @include mq() {
      margin-bottom: 30px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .library-tab {
    .tab-list {
      display: flex;
      justify-content: space-between;
      margin: 0 auto 40px;

      @include mq(medium, min, true) {
        max-width: 660px;
      }

      @include mq() {
        margin-bottom: 25px;
      }

      .tab-item {
        position: relative;
        width: 50%;
        padding: 25px 0 27px;
        background: $gray-f5f5f5;

        @include mq() {
          padding: 20px 0 22px;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: linear-gradient(to right, $accent-color, $accent-color02);

          @include mq(medium, min, true) {
            @include ease(opacity);
          }
        }

        .c-title {
          position: relative;
          z-index: 2;
          line-height: 1.3;
          color: #fff;

          .ja {
            font-size: 1.8rem;

            @include mq() {
              font-size: 1.7rem;
            }
          }
        }

        &:not(.is-active) {
          cursor: pointer;

          .c-title {
            color: $gray-bfbfbf;
          }
        }

        &.is-active {
          &:after {
            opacity: 1;
          }

          &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            border: 10px solid transparent;
            border-top: 12px solid #bd3327;

            @include mq() {
              margin-left: -8px;
              border-width: 8px;
              border-top-width: 10px;
            }
          }
        }

        &:hover {
          &:after {
            @include mq(medium, min, true) {
              opacity: 1;
            }
          }

          .c-title {
            @include mq(medium, min, true) {
              color: #fff;
            }
          }
        }
      }
    }

    .tab-cont {
      transition: all 0.3s;

      &:not(.is-active) {
        overflow: hidden;
        height: 0;
        transform: translateY(40px);
        opacity: 0;
      }
    }
  }

  .library-image {
    overflow-x: auto;

    @include mq() {
      margin: 0 -15px;
    }

    img {
      @include mq() {
        min-width: 780px;
        margin: 0 15px;
      }
    }
  }
}
