@charset "utf-8";

.c-textarea {
  textarea {
    width: 100%;
    height: 176px;
    padding: 15px;
    border: 1px solid $gray-dedede;
    border-radius: 4px;
    background-color: $gray-f5f5f5;

    @include mq(medium, min, true) {
      max-width: 596px;
    }

    @include mq() {
      height: 160px;
    }
  }
}
