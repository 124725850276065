@charset "utf-8";

.c-linklist {
  .linklist-title {
    position: relative;
    padding: 30px 10px 150px;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    background-color: $gray-f5f5f5;

    @include mq(medium, min, true) {
      margin-bottom: -122px;
    }

    @include mq(large) {
      font-size: 2rem;
    }

    @include mq() {
      padding: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, $accent-color, $accent-color02);
    }

    .icon {
      display: inline-block;
      width: 28px;
      margin-top: -5px;
      margin-right: 18px;
      vertical-align: middle;

      @include mq(medium, min, true) {
        @include mq(large) {
          display: block;
          margin: 0 auto 10px;
        }
      }

      @include mq() {
        margin-top: -5px;
        margin-right: 15px;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;

      @include mq(medium, min, true) {
        @include ease(opacity);
      }

      &:hover {
        @include mq(medium, min, true) {
          opacity: 0.7;
        }

        img {
          @include mq(medium, min, true) {
            opacity: 1;
          }
        }
      }
    }
  }

  .linklist-text {
    position: relative;
    font-size: 1.5rem;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(#000, 0.04);

    ul {
      li {
        border-bottom: 1px solid $gray-efefef;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 15px 44px 15px 24px;
      text-decoration: none;

      @include mq(medium, min, true) {
        @include ease(color);
      }

      &:not(.blank) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 25px;
          bottom: 0;
          width: 8px;
          height: 12px;
          margin: auto 0;
          background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
          background-size: auto 12px;
        }
      }

      &.blank {
        background: url(/assets/images/common/icons/blank.svg) no-repeat right 20px center;
        background-size: 16px auto;
      }

      &:hover {
        @include mq(medium, min, true) {
          text-decoration: underline;
          color: $accent-color03;
        }
      }
    }
  }
}
