@charset "utf-8";

// アコーディオン設定
.js-accordion {
  .js-accordionCont {
    overflow: hidden;
    max-height: 0;
  }

  &.is-animation {
    @include ease(all, 0.8s);

    .js-accordionCont {
      @include ease(all, 0.8s);
    }
  }

  &.is-active {
    .js-accordionCont {
      max-height: 100vh;
    }
  }
}

// モーダル設定
.remodal-wrapper {
  padding: 40px;

  @include mq(large) {
    padding: 20px;
  }

  @include mq() {
    padding: 15px;
  }
}

.remodal-overlay {
  background-color: rgba(#000, 0.9);
}

.remodal {
  max-width: 1160px;
  margin-top: 80px;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;

  @include mq() {
    margin-top: 45px;
  }
}

.remodal-close {
  top: -80px;
  right: 0;
  left: auto;

  @include mq(medium, min, true) {
    @include ease(opacity);
    width: 60px;
    height: 60px;
  }

  @include mq() {
    top: -45px;
  }

  &:before {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    background: url(/assets/images/common/icons/close.svg) no-repeat center right;
    background-size: auto 60px;

    @include mq() {
      width: 35px;
      height: 35px;
      background-size: auto 35px;
    }
  }

  &:hover {
    @include mq(medium, min, true) {
      opacity: 0.7;
    }
  }
}

.remodal-box {
  background-color: #fff;
}

// スクロールヒント
.scroll-hint-icon {
  height: 88px;
}
