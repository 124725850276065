@charset "utf-8";

#investors {
  .c-heading-jumbotron {
    height: 420px;

    @include mq() {
      height: 190px;
    }

    .heading-title {
      span {
        font-size: 3.8rem;
        color: $base-text-color;

        @include mq() {
          font-size: 3rem;
        }

        @include mq(xxsmall) {
          font-size: 2.6rem;
        }

        &:before {
          background-color: transparent;
        }
      }
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          &.img01 {
            background-image: url(/assets/images/investors/heading_bg.jpg);

            @include mq() {
              background-image: url(/assets/images/investors/heading_bg_sp.jpg);
            }
          }
        }
      }
    }
  }

  .information-block {
    background-color: $gray-666;

    .container {
      @include mq() {
        padding: 0;
      }
    }

    .information-detail {
      font-size: 1.5rem;
      color: #fff;

      @include mq(medium, min, true) {
        display: flex;
      }

      @include mq() {
        font-size: 1.3rem;
      }

      .detail-title {
        position: relative;
        padding: 20px 0;
        background-color: $gray-666;

        @include mq(medium, min, true) {
          display: flex;
          width: 160px;
          align-items: center;
          flex-shrink: 0;
        }

        @include mq() {
          padding: 15px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          background: linear-gradient(to right, $gray-4c4c4c 50%, $base-color);

          @include mq() {
            width: 100%;
            background: linear-gradient(to right, $gray-4c4c4c, $base-color);
          }
        }

        span {
          position: relative;
          z-index: 1;
          width: 100%;
          padding-left: 33px;
          font-size: 1.6rem;
          font-weight: bold;
          background: url(/assets/images/common/icons/attention.svg) no-repeat center left;

          @include mq() {
            font-size: 1.3rem;
          }
        }
      }

      .detail-text {
        padding: 20px 25px;

        @include mq(medium, min, true) {
          flex: 1;
        }

        @include mq() {
          padding: 15px;
        }

        a {
          text-decoration: none;

          &:hover {
            @include mq(medium, min, true) {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .dashboard-block {
    padding-top: 40px;

    @include mq() {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    .dashboard-box{
      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
      }

      .box-left {
        @include mq(medium, min, true) {
          width: percentage(540/1160);
        }
      }

      .box-right {
        @include mq(medium, min, true) {
          width: percentage(580/1160);
        }

        @include mq() {
          margin-top: 30px;
        }
      }
    }

    .dashboard-tab {
      .tab-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .tab-item {
          position: relative;
          width: 50%;
          padding: 18px 0;
          background: $gray-f5f5f5;

          @include mq() {
            padding: 12px 0;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }

          .c-title {
            position: relative;
            z-index: 2;
            line-height: 1.3;
            color: #fff;

            .ja {
              font-size: 1.8rem;

              @include mq(medium, min, true) {
                margin-bottom: 2px;
              }

              @include mq() {
                font-size: 1.7rem;
              }
            }

            .en {
              font-size: 1.2rem;

              @include mq() {
                font-size: 1.1rem;
              }
            }
          }

          &:not(.is-active) {
            cursor: pointer;

            .c-title {
              color: $gray-bfbfbf;
            }
          }

          &.is-active {
            &:after {
              opacity: 1;
            }

            &:before {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -10px;
              border: 10px solid transparent;
              border-top: 12px solid #bd3327;

              @include mq() {
                margin-left: -8px;
                border-width: 8px;
                border-top-width: 10px;
              }
            }
          }

          &:hover {
            &:after {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }

            .c-title {
              @include mq(medium, min, true) {
                color: #fff;
              }
            }
          }
        }
      }

      .tab-cont {
        transition: all 0.3s;

        &:not(.is-active) {
          overflow: hidden;
          height: 0;
          transform: translateY(40px);
          opacity: 0;
        }

        .cont-link {
          margin-top: 30px;
        }

        .cont-button {
          margin: 30px auto 0;

          @include mq(medium, min, true) {
            max-width: 300px;
          }
        }

        &#tab-calendar {
          .XJTxtDPDataTableTr {
            &:nth-child(n+6) {
              display: none;
            }
          }
        }
      }
    }

    .dashboard-stock {
      padding: 20px 30px 25px;
      box-shadow: 0 0 20px rgba(#000, 0.04);

      @include mq(large) {
        padding: 20px 25px 25px;
      }

      @include mq() {
        padding: 30px;
      }

      @include mq(xxsmall) {
        padding: 30px 15px;
      }

      .stock-detail {
        @include mq(medium, min, true) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .detail-title {
          @include mq(medium, min, true) {
            // ▼ 株価リアルタイム反映を一旦非表示
            // width: percentage(240/510);
            // margin-right: percentage(20/510);
            // padding-right: percentage(20/510);
            // border-right: 1px solid $gray-efefef;
          }

          @include mq() {
            margin-bottom: 10px;
            padding-bottom: 15px;

            // ▼ 株価リアルタイム反映を一旦非表示
            // border-bottom: 1px solid $gray-efefef;
          }

          .large {
            margin-right: 5px;
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1;

            @include mq(large) {
              font-size: 1.8rem;
            }

            @include mq() {
              margin-right: 15px;
              font-size: 2rem;
            }
          }

          .small {
            display: inline-block;
            font-size: 1.3rem;

            @include mq(medium, min, true) {
              @include mq(large) {
                font-size: 1.1rem;
              }
            }
          }

          .date {
            margin-top: 5px;
            font-size: 1.3rem;

            @include mq(medium, min, true) {
              @include mq(large) {
                font-size: 1.1rem;
              }
            }
          }
        }

        .detail-text {
          flex: 1;
          min-width: 145px;
          font-weight: bold;

          @include mq() {
            text-align: right;
          }

          .small,
          .large {
            display: inline-block;
          }

          .small {
            font-size: 1.5rem;

            @include mq() {
              font-size: 1.2rem;
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }

          .large {
            font-size: 5.6rem;
            line-height: 1;

            @include mq(large) {
              font-size: 4rem;
            }

            @include mq() {
              font-size: 4.5rem;
            }
          }
        }
      }

      .stock-button {
        margin: 15px auto 0;

        @include mq(medium, min, true) {
          max-width: 300px;
        }
      }
    }

    .dashboard-document {
      margin-top: 25px;
      padding-bottom: 25px;
      background-color: $gray-f5f5f5;

      .document-button {
        margin: 20px auto 0;
        padding: 0 25px;

        @include mq(medium, min, true) {
          max-width: 350px;
        }

        @include mq() {
          padding: 0 30px;
        }

        @include mq(xxsmall) {
          padding: 0 15px;
        }
      }
    }
  }

  .menu-block {
    padding-top: 70px;

    @include mq() {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .container {
      max-width: 1360px;

      @include mq() {
        padding: 0;
      }
    }

    .menu-list {
      margin-top: 50px;

      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      @include mq() {
        margin-top: 25px;
      }

      .menu-item {
        @include mq(medium, min, true) {
          width: percentage(400/1280);
        }

        &:not(:nth-child(3n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(40/1280);
          }
        }

        &:nth-child(n + 4) {
          @include mq(medium, min, true) {
            margin-top: 40px;
          }
        }

        + .menu-item {
          @include mq() {
            margin-top: 15px;
          }
        }

        .c-linklist {
          @include mq() {
            background-color: $gray-f5f5f5;
          }

          &.is-active {
            @include mq() {
              padding-bottom: 25px;
            }

            .linklist-title {
              &:before {
                @include mq() {
                  background-position: center right;
                }
              }
            }
          }

          .linklist-title {
            @include mq() {
              position: relative;
              margin: 0;
              padding: 25px 24px 20px;
              cursor: pointer;
              text-align: left;
            }

            @include mq(xxsmall) {
              font-size: 1.8rem;
            }

            &:before {
              @include mq() {
                content: "";
                position: absolute;
                top: 0;
                right: 15px;
                bottom: 0;
                width: 24px;
                height: 28px;
                margin: auto 0;
                background: url(/assets/images/common/icons/accordion.svg) no-repeat center left;
              }
            }
          }

          .linklist-text {
            margin: 0 24px;

            @include mq(medium, min, true) {
              max-height: 100vh;

              @include mq(large) {
                font-size: 1.3rem;
              }
            }

            @include mq() {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  .col2-block {
    padding-bottom: 120px;

    @include mq() {
      padding-top: 30px;
      padding-bottom: 70px;
    }

    .container {
      max-width: 1060px;
    }

    .col2-box {
      @include mq(medium, min, true) {
        display: flex;
      }

      .box-left {
        @include mq(medium, min, true) {
          width: percentage(450/980);
        }
      }

      .box-right {
        @include mq(medium, min, true) {
          width: percentage(490/980);
          margin-left: percentage(40/980);
          padding-left: percentage(40/980);
          border-left: 1px solid $gray-efefef;
        }

        @include mq() {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid $gray-efefef;
        }
      }

      .box-text {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.9;

        @include mq() {
          margin-top: 15px;
        }
      }

      .box-link {
        margin-top: 20px;
      }

      .box-button {
        margin-top: 25px;

        .c-button {
          @include mq() {
            color: #fff;
          }

          a {
            &:after {
              @include mq() {
                background-color: transparent;
              }
            }

            span {
              &:after {
                @include mq() {
                  background-position: center right;
                }
              }
            }
          }
        }
      }

      .box-note {
        margin-top: 25px;
        font-weight: 500;
        text-align: right;

        @include mq() {
          font-size: 1.4rem;
        }
      }
    }
  }
}
