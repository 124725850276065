@charset "utf-8";

#premium-yutai {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/individual-investors/premium-yutai/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/individual-investors/premium-yutai/heading_bg_sp.jpg);
    }
  }

  .l-column {
    @include mq() {
      padding-top: 40px;
    }
  }

  .section {
    padding-top: 0;
  }

  .section-title {
    margin-bottom: 25px;

    @include mq() {
      margin-bottom: 10px;
    }
  }

  .section-subtitle {
    margin-bottom: 20px;

    @include mq() {
      margin-bottom: 15px;
    }

    .c-heading-underline--type03 {
      @include mq() {
        padding-bottom: 15px;
      }
    }
  }

  .section-text {
    font-size: 1.5rem;

    p + p {
      margin-top: 30px;
    }
  }

  .section-detail {
    .detail-title {
      font-size: 1.6rem;
      font-weight: bold;

      &.large {
        font-size: 1.8rem;
      }
    }

    .detail-text {
      margin-top: 10px;
      font-size: 1.5rem;

      .list,
      .note {
        li {
          padding-left: 1em;
          text-indent: -1em;
        }
      }

      .note {
        font-size: 1.2rem;
        color: $gray-444;

        @include mq() {
          line-height: 1.5;
        }
      }
    }

    + .section-detail {
      margin-top: 25px;
    }
  }

  // 株主優待制度
  .about-block {
    @include mq() {
      padding-bottom: 25px;
    }

    .section-text {
      margin-bottom: 23px;

      @include mq() {
        margin-bottom: 35px;
      }
    }
  }

  // 1. 株主優待制度の目的
  .purpose-block {
    padding-bottom: 90px;

    @include mq() {
      padding-bottom: 85px;
    }
  }

  // 2. 株主優待制度の内容
  .detail-block {
    .section-text {
      margin-bottom: 28px;
    }

    .section-detail {
      .detail-text {
        .c-table .table-text .title,
        .c-table .table-text .text {
          @include mq() {
            padding-right: 8px;
            padding-left: 8px;
            font-size: 1.4rem;
          }
        }

        .note {
          padding-top: 5px;
          padding-left: 2em;
          text-indent: -2em;
        }
      }
    }
  }

  // 3. 株主優待制度の利用方法及び内容
  .howto-block {
    @include mq(medium, min, true) {
      padding-bottom: 50px;
    }
  }

  // 株主優待についてのお問い合わせ
  .contact-block {
    padding: 25px 30px 30px;
    border: 1px solid $gray-dedede;

    @include mq() {
      padding: 20px 15px;
    }

    .section-detail .detail-text {
      @include mq() {
        margin-top: 15px;
      }
    }
  }
}
