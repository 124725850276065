@charset "utf-8";

// Media Queries
@mixin mq($breakpoint: medium, $rule: max, $addition: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == min and $addition {
    $breakpoint: $breakpoint + 0.0625;
  }
  @media (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// Transition
@mixin ease($property: all, $duration: 0.2s, $timing-function: ease) {
  transition: $property $duration $timing-function;
}

// プレスリリース エディター
@mixin pressEdit() {
  // 見出し1
  h1 {
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 20px;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;

    @include mq() {
      font-size: 2.2rem;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
    }

    &:before {
      width: 100%;
      background-color: $gray-dedede;
    }

    &:after {
      width: 260px;
      background: linear-gradient(to right, $accent-color, $accent-color02);

      @include mq() {
        width: 75px;
      }
    }
  }

  // 見出し2
  h2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.2;
    border-bottom: 1px solid $accent-color03;

    @include mq() {
      font-size: 2rem;
    }
  }

  // 見出し3
  h3 {
    margin-bottom: 30px;
    padding-left: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    color: $accent-color03;
    border-left: 4px solid $accent-color03;

    @include mq() {
      font-size: 1.6rem;
    }
  }

  // 段落
  p {
    font-size: 1.6rem;
    line-height: 2;
  }

  p,
  ol,
  ul {
    &:not(:last-child) {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 40px;
      }
    }
  }

  // 太字
  strong {
    font-weight: bold;
  }

  // イタリック
  em {
    font-style: italic;
  }

  // 打ち消し線
  s {
    text-decoration: line-through;
  }

  // 番号付きリスト
  // 箇条書き
  ol,
  ul {
    padding-left: 1em;
  }

  // 箇条書き
  ul {
    list-style: disc;
  }

  img {
    width: auto!important;
    height: auto!important;;
  }

  // 「スタイル」プルダウンの中
  // 画像関連のmargin
  .img-box,
  .movie {
    &:not(:last-child) {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 40px;
      }
    }
  }

  // 画像1カラム(中央揃え、サンプル画像付きで表示)
  .img-box--col1 {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  // 画像2カラム(サンプル画像付きで表示、SP1カラム)
  .img-box--col2--pc {
    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
    }

    .image {
      @include mq(medium, min, true) {
        width: calc(50% - 15px);
      }

      &:not(:last-child) {
        @include mq() {
          margin-bottom: 20px;
        }
      }
    }
  }

  //  画像 + テキスト(画像右、SP画像上テキスト下)
  .img-box--text {
    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
    }

    .image {
      @include mq(medium, min, true) {
        max-width: 45%;
        flex-shrink: 0;
        margin-left: 30px;
      }

      @include mq() {
        margin-top: 20px;
        text-align: center;
      }
    }

    .text {
      @include mq(medium, min, true) {
        flex: 1;
      }
    }

    // 画像 + テキスト(画像左、SP画像上テキスト下)
    &.reverse {
      @include mq(medium, min, true) {
        flex-direction: row-reverse;
      }

      .image {
        @include mq(medium, min, true) {
          margin-right: 30px;
          margin-left: 0;
        }
      }
    }

    // 画像サイズ大
    &__large {
      .image {
        @include mq(medium, min, true) {
          max-width: 65%;
        }
      }
    }

    // 画像サイズ小
    &__small {
      .image {
        @include mq(medium, min, true) {
          max-width: 35%;
        }
      }
    }
  }

  // 文字色(赤)
  .fc-red {
    color: $accent-color03;
  }

  // 文字色(赤)
  .fc-gray {
    color: $gray-666;
  }

  // 動画(Youtube限定)
  .movie {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
