@charset "utf-8";

#announcements {

  .c-newslist {
    margin-bottom: 40px;

    @include mq() {
      margin-bottom: 25px;
    }

    .news-list {
      .news-item {
        a {
          padding: 30px 0;

          .news-detail {
            .detail-text {
              display: inline-block;
              padding-right: 30px;
              background-repeat: no-repeat;
              background-position: center right;
              background-size: auto 24px;

              &.pdf {
                background-image: url(/assets/images/common/icons/pdf.svg);
              }
            }
          }
        }
      }
    }
  }

  .library-text {
    margin-bottom: 50px;

    @include mq() {
      margin-bottom: 40px;
    }

    p {
      + p {
        margin-top: 40px;
      }
    }
  }

  .announcements-adobe {
    @include mq(medium, min, true) {
      display: flex;
      align-items: center;
    }

    .adobe-image {
      width: 159px;

      @include mq(medium, min, true) {
        flex-shrink: 0;
        margin-right: 20px;
      }

      @include mq() {
        margin-bottom: 20px;
      }
    }

    .adobe-text {
      font-size: 1.3rem;
    }
  }
}
