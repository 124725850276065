@charset "utf-8";

.l-header {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;

  @include mq(large) {
    padding-bottom: 5px;
  }

  @include mq() {
    padding: 8px 0;
  }

  .container {
    @include mq() {
      padding-right: 8px;
    }
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mq() {
      align-items: center;
    }
  }

  .header-logo {
    width: 190px;

    @include mq() {
      width: 140px;
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .header-contact,
  .header-network {
    margin-left: 30px;
    font-size: 1.3rem;

    @include mq(large) {
      font-size: 1.2rem;
    }

    a {
      text-decoration: none;

      @include mq(medium, min, true) {
        @include ease(opacity);
      }

      &:hover {
        @include mq(medium, min, true) {
          opacity: 0.7;
        }
      }
    }
  }

  .header-network {
    a {
      padding-left: 20px;
      background: url(/assets/images/common/icons/network.svg) no-repeat center left;
      background-size: auto 15px;
    }
  }

  .header-trigger {
    @include ease(background-color);
    position: relative;
    width: 72px;
    height: 48px;
    margin-left: 8px;
    cursor: pointer;

    .hamburger {
      position: absolute;
      top: 10px;
      right: 0;
      left: 0;
      width: 32px;
      height: 14px;
      margin: 0 auto;

      span {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        border-radius: 4px;
        background-color: $base-color;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 6px;
          opacity: 1;
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }

      &.is-animation {
        span {
          &:nth-of-type(1) {
            animation: menu-bar01 0.3s forwards;
          }

          &:nth-of-type(2) {
            transition: all 0.1s 0.1s;
          }

          &:nth-of-type(3) {
            animation: menu-bar02 0.3s forwards;
          }
        }
      }
    }

    &:after {
      content: "メニュー";
      position: absolute;
      right: 0;
      bottom: 5px;
      left: 0;
      margin: 0 auto;
      font-size: 1rem;
      text-align: center;
    }
  }

  .header-nav {
    @include mq(medium, min, true) {
      max-width: 70%; // 12月公開時のみ
      margin: 10px auto 0;
    }

    @include mq() {
      @include ease(height, 0.5s);
      position: absolute;
      top: 56px;
      right: 0;
      left: 0;
      overflow: hidden;
      height: 0;
      background-color: #fff;
    }

    .nav-list {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq(large) {
          width: 125.3%;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }

      @include mq() {
        padding: 20px 15px;
      }

      .nav-item {
        position: relative;
        font-size: 1.5rem;

        @include mq() {
          margin-bottom: 20px;
        }

        &.icon {
          padding-right: 10px;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 0;
            height: 0;
            transform: translateY(-50%);
            border-width: 5px 4px 0 4px;
            border-style: solid;
            border-color: $gray-bfbfbf transparent transparent transparent;

            @include mq(medium, min, true) {
              right: 0;
            }
          }
        }

        a {
          position: relative;
          display: inline-block;
          padding-bottom: 8px;
          text-decoration: none;

          svg {
            overflow: initial;
            font-family: $base-font-family;
            letter-spacing: -0.05em;

            @media all and (-ms-high-contrast: none) {
              height: 16px;
            }
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 0px;
            height: 2px;
            margin: 0 auto;
          }

          &:hover {
            svg {
              @include mq(medium, min, true) {
                font-weight: 500;
                opacity: 1;
                fill: url(#gradation);
              }
            }
          }

          &.is-active {
            svg {
              font-weight: 500;
              fill: url(#gradation);
            }

            &:after {
              width: 100%;
              background: linear-gradient(to right, $accent-color, $accent-color02);
            }
          }
        }
      }
    }
  }

  .lang-list {
    display: flex;
    overflow: hidden;
    margin-left: 25px;
    border-radius: 4px;

    @include mq() {
      margin-left: 8px;
    }

    .lang-item {
      font-size: 1.2rem;
      font-weight: bold;

      @include mq() {
        font-size: 1rem;
      }

      a {
        position: relative;
        display: block;
        padding: 5px 13px 7px;
        text-decoration: none;
        color: $gray-666;
        background-color: $gray-efefef;

        @include mq(medium, min, true) {
          @include ease(opacity);
        }

        @include mq() {
          padding: 5px 8px;
        }

        &:not(.is-active) {
          &:hover {
            @include mq(medium, min, true) {
              opacity: 0.7;
            }
          }
        }

        &.is-active {
          color: #fff;
          background-color: $gray-666;
        }
      }
    }
  }

  &.is-active {
    .header-trigger {
      background-color: $gray-f5f5f5;

      .hamburger {
        &.is-animation {
          span {
            &:nth-of-type(1) {
              animation: active-menu-bar01 0.3s both;
            }

            &:nth-of-type(2) {
              opacity: 0;
            }

            &:nth-of-type(3) {
              animation: active-menu-bar03 0.3s both;
            }
          }
        }
      }

      &:after {
        content: "閉じる";
      }
    }

    .header-nav {
      @include mq() {
        overflow: auto;
        height: calc(100vh - 56px);
      }
    }
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(6px) rotate(25deg);
  }
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-6px) rotate(-25deg);
  }
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(6px) rotate(0);
  }
  100% {
    transform: translateY(6px) rotate(25deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-6px) rotate(0);
  }
  100% {
    transform: translateY(-6px) rotate(-25deg);
  }
}
