@charset "utf-8";

#ir-calendar {
  .l-column {
    @include mq() {
      padding-top: 30px;
    }
  }

  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-calendar/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-calendar/heading_bg_sp.jpg);
    }
  }

  .c-newslist .news-list .news-item .news-detail .detail-title .date {
    font-family: $base-font-family;
  }

  .calendar-year {
    margin-bottom: 45px;

    @include mq() {
      margin-bottom: 30px;
    }

    .year-title {
      padding: 16px 15px;
      font-size: 2.4rem;
      background-color: $gray-f5f5f5;

      @include mq() {
        font-size: 2.2rem;
      }
    }
  }
}
