@charset "utf-8";

#company-portfolio.investment {
  .investment-block {
    padding: 20px 0 180px;

    @include mq() {
      padding-top: 20px;
      padding-bottom: 120px;
    }

    .investment-title {
      margin-bottom: 20px;
    }

    .investment-text {
      margin-bottom: 35px;
      font-size: 1.5rem;

      @include mq(medium, min, true) {
        text-align: center;
      }
    }

    .investment-nav-links {
      display: flex;
      margin-bottom: 60px;
      flex-wrap: wrap;

      @include mq() {
        margin-bottom: 50px;
        justify-content: space-between;
      }

      .investment-nav-link {
        width: percentage(224/1160);

        @include mq() {
          width: percentage(170/345);
        }

        &:not(:nth-child(5n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(10/1160);
          }
        }

        &:nth-child(n+6) {
          @include mq(medium, min, true) {
            margin-top: 10px;
          }
        }

        &:nth-child(n+3) {
          @include mq() {
            margin-top: 5px;
          }
        }

        a {
          position: relative;
          display: flex;
          height: 56px;
          transition: opacity 0.2s ease;
          text-align: center;
          text-decoration: none;
          border: 1px solid $gray-dedede;
          align-items: center;

          @include mq() {
            height: 48px;
            font-size: 1.3rem;
            line-height: 1.2;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }

          span {
            position: relative;
            z-index: 1;
            width: 100%;
          }

          &:hover {
            @include mq(medium, min, true) {
              color: #fff;
              border: none;
            }

            &:after {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .investment-box {
      .box-item {
        + .box-item {
          margin-top: 80px;

          @include mq() {
            margin-top: 55px;
          }
        }
      }

      .box-title {
        padding-bottom: 20px;
        font-size: 2.2rem;
        font-weight: bold;
        border-bottom: 1px solid $gray-333;

        @include mq() {
          padding-bottom: 15px;
          font-size: 2rem;
        }
      }
    }

    .investment-detail {
      display: flex;
      flex-wrap: wrap;

      @include mq() {
        justify-content: space-between;
      }

      .detail-item {
        width: percentage(200/1160);
        margin-top: 40px;

        @include mq() {
          width: calc(50% - 7px);
          margin-top: 30px;
        }

        &:not(:nth-child(5n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(40/1160);
          }
        }

        a {
          display: block;
          text-decoration: none;

          @include mq(medium, min, true) {
            @include ease(opacity);
          }

          &:hover {
            @include mq(medium, min, true) {
              opacity: 0.7;
            }
          }
        }

        .detail {
          .detail-image {
            position: relative;
            text-align: center;
            border: 1px solid $gray-dedede;

            @include mq(medium, min, true) {
              padding: 20px;
            }

            &:after {
              content: "";
              position: absolute;
              right: 10px;
              bottom: 10px;
              width: 16px;
              height: 16px;
              background: url(/assets/images/common/icons/blank.svg) no-repeat right center;
              background-size: 16px auto;
            }
          }

          .detail-title {
            margin-top: 10px;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
          }
        }

        .crunchbase {
          margin-top: 10px;

          a {
            position: relative;
            display: inline-block;
            padding: 8px 30px 8px 10px;
            font-size: 1.2rem;
            line-height: 1;
            color: #808080;
            border: 1px solid $gray-dedede;
            border-radius: 4px;
            background-color: $gray-f5f5f5;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 8px;
              bottom: 0;
              width: 8px;
              height: 12px;
              margin: auto 0;
              opacity: 0.4;
              background: url(/assets/images/common/buttons/arrow.svg) no-repeat center center;
              background-size: auto 12px;
            }
          }
        }
      }
    }
  }
}
