@charset "utf-8";

#sitemap {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .sitemap-block {
    padding: 40px 0 125px;

    @include mq() {
      padding: 20px 0 70px;
    }

    a {
      text-decoration: none;
    }

    .sitemap-list {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      + .sitemap-list {
        @include mq(large, min, true) {
          margin-top: 90px;
        }

        @include mq(medium, min, true) {
          @include mq(large) {
            margin-top: 40px;
          }
        }

        @include mq() {
          margin-top: 30px;
        }
      }

      .sitemap-item {
        @include mq(medium, min, true) {
          width: percentage(360/1160);
        }

        &:not(:nth-child(3n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(40/1160);
          }
        }

        + .sitemap-item {
          @include mq() {
            margin-top: 30px;
          }
        }

        .c-heading-underline {
          padding-bottom: 0;

          @include mq(medium, min, true) {
            font-size: 2rem;
          }

          a {
            display: block;
            padding: 20px 0;

            @include mq(medium, min, true) {
              @include ease(opacity);
            }

            &:hover {
              @include mq(medium, min, true) {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    .sitemap-bottom {
      margin-top: 55px;
      padding-top: 10px;
      border-top: 1px solid $gray-dedede;

      @include mq() {
        margin-top: 40px;
      }

      .link-list {
        display: flex;
        flex-wrap: wrap;

        .link-item {
          margin-right: 50px;
        }
      }
    }

    .link-list {
      font-size: 1.5rem;

      .link-item {
        margin-top: 15px;

        a {
          position: relative;
          display: inline-block;
          padding-left: 15px;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 8px;
            height: 12px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 12px;
          }

          &:hover {
            @include mq(medium, min, true) {
              text-decoration: underline;
            }
          }

          &.blank {
            padding-right: 20px;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 16px;
              height: 16px;
              margin: auto 0;
              background: url(/assets/images/common/icons/blank.svg) no-repeat center left;
              background-size: auto 16px;
            }
          }
        }
      }
    }
  }
}
