@charset "utf-8";

#corporategovernance {
  .l-column {
    padding-top: 60px;

    @include mq() {
      padding-top: 35px;
    }
  }

  .information-text {
    line-height: 2;
  }

  // コーポレート・ガバナンス 基本的な考え方
  .corporategovernance-block {
    @include mq(medium, min, true) {
      padding-bottom: 85px;
    }

    .corporategovernance-pdf {
      margin-top: 50px;

      @include mq() {
        margin-top: 20px;
      }

      .c-newslist {
        margin-bottom: 55px;

        @include mq() {
          margin-bottom: 40px;
        }

        .news-list {
          .news-item {
            a {
              .news-detail {
                .detail-text {
                  display: inline-block;
                  padding-right: 30px;
                  background-repeat: no-repeat;
                  background-position: center right;
                  background-size: auto 24px;

                  &.pdf {
                    background-image: url(/assets/images/common/icons/pdf.svg);
                  }
                }
              }
            }
          }
        }
      }
    }

    .corporategovernance-adobe {
      @include mq(medium, min, true) {
        display: flex;
        align-items: center;
      }

      .adobe-image {
        width: 159px;

        @include mq(medium, min, true) {
          flex-shrink: 0;
          margin-right: 20px;
        }

        @include mq() {
          margin-bottom: 20px;
        }
      }

      .adobe-text {
        font-size: 1.3rem;
      }
    }
  }

  // 体　制
  .organization-block {
    @include mq(medium, min, true) {
      padding-bottom: 65px;
    }

    .organization-image {
      max-width: 940px;
      margin: 45px auto 0;

      @include mq() {
        margin-top: 30px;
      }
    }
  }

  // 役　員
  // 役員一覧
  .officer-block {
    .executive-members {
      padding-top: 20px;
      padding-bottom: 70px;

      @include mq() {
        padding-bottom: 50px;
      }
    }

    .officer-table {
      .c-table {
        @include mq() {
          display: block;
        }

        tbody {
          @include mq() {
            display: block;
          }
        }

        .table-text {
          @include mq() {
            display: block;
            border: none;
          }

          .text {
            padding-right: 40px;
            line-height: 1.8;
            vertical-align: top;

            @include mq() {
              display: block;
              width: 100%;
              padding: 0;
              background-color: $gray-f5f5f5;
            }

            p {
              &:not(.sub) {
                @include mq() {
                  padding: 10px;
                  border-bottom: 1px solid $gray-dedede;
                }
              }
            }

            .name {
              font-size: 1.8rem;
              font-weight: bold;
            }

            .sub {
              @include mq(medium, min, true) {
                display: none;
              }

              @include mq() {
                padding: 10px;
                font-weight: bold;
                border-bottom: 2px solid $base-color;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
