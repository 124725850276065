@charset "utf-8";

.l-sidenav {
  .sidenav-document {
    margin-bottom: 30px;
    padding-bottom: 20px;
    background-color: $gray-f5f5f5;

    .c-documentlist {
      .document-detail {
        .detail-title {
          padding: 25px;
        }

        .detail-text {
          padding-right: 20px;
          padding-left: 20px;

          @include mq(xxsmall) {
            padding-right: 15px;
            padding-left: 15px;
          }
        }
      }
    }

    .document-button {
      margin: 20px auto 0;
      padding: 0 20px;

      @include mq(medium, min, true) {
        max-width: 360px;
      }

      @include mq(xxsmall) {
        padding: 0 15px
      }

      .c-button {
        a,
        button {
          span {
            @include mq(large) {
              padding-left: 30px;
            }
          }
        }
      }
    }
  }

  .sidenav-menu {
    .c-linklist {
      overflow: hidden;

      .linklist-title {
        padding-left: 25px;

        @include mq(medium, min, true) {
          @include mq(large) {
            font-size: 2.2rem;
          }
        }
      }

      .linklist-text {
        @include mq(medium, min, true) {
          @include mq(large) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
