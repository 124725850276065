@charset "utf-8";

.c-title {
  font-weight: bold;

  .ja {
    display: block;
    font-size: 2.8rem;
    line-height: 1;

    @include mq() {
      font-size: 2.6rem;
    }
  }

  .en {
    display: inline-block;
    font-family: $font-barlow;
    font-size: 1.2rem;

    svg {
      overflow: initial;
      font-family: inherit;
      fill: url(#gradation);

      @media all and (-ms-high-contrast: none) {
        height: 13px;
      }
    }
  }

  &--sub {
    font-size: 2.2rem;
    font-weight: bold;

    @include mq() {
      font-size: 2rem;
    }
  }
}
