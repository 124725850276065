@charset "utf-8";

#stock-information {
  .l-column {
    padding-top: 60px;

    @include mq() {
      padding-top: 35px;
    }
  }

  .information-title {
    @include mq(medium, min, true) {
      margin-bottom: 30px;
    }
  }

  .information-table {
    .c-table {
      @include mq() {
        display: block;
      }

      tbody {
        @include mq() {
          display: block;
        }
      }

      .table-text {
        @include mq() {
          display: block;
        }

        .title,
        .text {
          vertical-align: top;

          @include mq() {
            display: block;
            width: 100%;
            padding: 12px 10px;
          }
        }

        .title {
          @include mq() {
            border-bottom: 1px solid $gray-dedede;
          }
        }

        .text {
          line-height: 1.6;
        }
      }
    }

    a {
      text-decoration: none;

      &:hover {
        @include mq(medium, min, true) {
          text-decoration: underline;
        }
      }
    }
  }
}

html[lang="ja"] {
  #stock-information .information-table .c-table .table-text .text {
    word-break: break-all;
  }
}
