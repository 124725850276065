@charset "utf-8";

.c-button {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: $accent-color03;

  @include mq(medium, min, true) {
    max-width: 300px;
  }

  a,
  button {
    position: relative;
    background: linear-gradient(to right, $accent-color, $accent-color02);

    &:after {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;

      @include mq(medium, min, true) {
        @include ease(background-color);
        transform: scale((298/300), (55/57));
      }

      @include mq() {
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);;
      }
    }

    span {
      position: relative;
      z-index: 1;
      display: block;
      padding: 15px 40px;

      @include mq(xxsmall) {
        padding: 15px 35px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        width: 8px;
        height: 12px;
        margin: auto 0;
        background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
        background-size: auto 12px;
      }
    }

    &:hover {
      @include mq(medium, min, true) {
        color: #fff;
      }

      &:after {
        @include mq(medium, min, true) {
          background-color: transparent;
        }
      }

      span {
        &:after {
          @include mq(medium, min, true) {
            background-position: center right;
          }
        }
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
  }

  button {
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 0;
  }

  // Fill
  &--fill {
    color: #fff;

    a,
    button {
      &:after {
        background-color: transparent;
      }

      span {
        &:after {
          background-position: center right;
        }
      }

      &:hover {
        @include mq(medium, min, true) {
          color: $accent-color03;
        }

        &:after {
          @include mq(medium, min, true) {
            background-color: #fff;
          }
        }

        span {
          &:after {
            @include mq(medium, min, true) {
              background-position: center left;
            }
          }
        }
      }
    }
  }

  // Size
  &--large {
    font-size: 2.4rem;
    font-weight: bold;

    @include mq(medium, min, true) {
      max-width: 450px;
    }

    a,
    button {
      span {
        padding: 19px 40px 20px;

        @include mq(xxsmall) {
          padding: 19px 30px 20px;
        }
      }
    }

    button {
      height: 80px;
    }
  }

  // Icon
  &--download {
    a,
    button {
      span {
        &:after {
          width: 18px;
          height: 16px;
          background-image: url(/assets/images/common/buttons/download.svg);
          background-size: auto 16px;
        }
      }
    }
  }

  &--blank {
    a,
    button {
      span {
        &:after {
          right: 12px;
          width: 16px;
          height: 16px;
          background-image: url(/assets/images/common/icons/blank.svg);
          background-size: auto 16px;
        }
      }

      &:hover {
        span {
          &:after {
            @include mq(medium, min, true) {
              background-image: url(/assets/images/common/icons/blank_w.svg);
            }
          }
        }
      }
    }
  }
}

// 下層ページへの誘導ボタン
.c-button-guide {
  a {
    position: relative;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.33333;
    transition: opacity 0.2s ease;
    text-decoration: none;
    align-items: center;
    border: 1px solid #dedede;

    &:after {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(to right, $accent-color, $accent-color02);

      @include mq(medium, min, true) {
        @include ease(opacity);
      }
    }

    span {
      position: relative;
      z-index: 1;
      width: 100%;
      padding-right: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 6px;
        bottom: 0;
        width: 8px;
        height: 12px;
        margin: auto 0;
        background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
        background-size: auto 12px;
      }
    }
  }

  &:not(.c-button-guide--large) {
    a {
      @include mq(medium, min, true) {
        @include ease(opacity);
      }

      &:hover {
        @include mq(medium, min, true) {
          opacity: 0.7;
        }
      }
    }
  }

  &--large {
    a {
      max-width: 360px;
      height: 80px;
      padding: 0 24px;
      font-size: 1.8rem;

      @include mq() {
        max-width: 100%;
        height: 60px;
        font-size: 1.5rem;
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
      }

      &:not(.is-active) {
        &:hover {
          @include mq(medium, min, true) {
            color: #fff;
          }

          &:after {
            @include mq(medium, min, true) {
              opacity: 1;
            }
          }

          span {
            &:after {
              @include mq(medium, min, true) {
                background-position: center right;
              }
            }
          }
        }
      }

      &.is-active {
        color: #fff;

        &:after {
          opacity: 1;
        }

        span {
          &:after {
            background-position: center right;
          }
        }
      }
    }
  }

  &--medium {
    a {
      max-width: 320px;
      height: 64px;
      padding: 0 16px;
      font-size: 1.6rem;
      text-align: center;
      color: #fff;
      border-color: #fff;

      @include mq() {
        max-width: 285px;
        height: 56px;
      }

      span {
        &::after {
          background-position: right center;
        }
      }
    }
  }

  &--small {
    a {
      max-width: 240px;
      height: 56px;
      padding: 0 16px;
      font-size: 1.5rem;
      text-align: center;
      color: #fff;
      border-color: #fff;

      @include mq() {
        max-width: 285px;
        height: 56px;
      }
    }
  }

  &--xsmall {
    a {
      max-width: 200px;
      height: 56px;
      padding: 0 16px;
      font-size: 1.5rem;
      text-align: center;
      color: #fff;
      border-color: #fff;

      @include mq() {
        max-width: 285px;
        height: 56px;
      }

      span {
        &::after {
          background-position: right center;
        }
      }
    }
  }

  &--dark {
    a {
      border: none;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
