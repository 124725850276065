@charset "utf-8";

#termsofuse {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .termsofuse-block {
    padding: 55px 0 160px;

    @include mq() {
      padding: 30px 0 65px;
    }

    .container {
      max-width: 1040px;
    }

    .termsofuse-detail {
      + .termsofuse-detail {
        margin-top: 45px;

        @include mq() {
          margin-top: 25px;
        }
      }

      .detail-title {
        margin-bottom: 25px;

        @include mq() {
          margin-bottom: 15px;
        }
      }

      .detail-text {
        font-size: 1.5rem;
        line-height: 1.8;

        > * {
          &:not(:last-child) {
            margin-bottom: 20px;

            @include mq() {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
