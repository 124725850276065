@charset "utf-8";

.l-breadcrumb {
  padding: 8px 0;
  background-color: $gray-666;

  .breadcrumb-list {
    display: flex;
    flex-wrap: wrap;

    .breadcrumb-item {
      font-size: 1.2rem;
      color: #fff;

      &:not(:last-child) {
        &:after {
          content: ">";
          display: inline-block;
          margin: 0 15px;
        }
      }

      a {
        text-decoration: none;

        &:hover {
          @include mq(medium, min, true) {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
