@charset "utf-8";

#segment {
  .highlights-list {
    padding-top: 15px;

    &:not(:last-of-type) {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 50px;
      }
    }
  }
}
