@charset "utf-8";

#company-portfolio {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .corporate-lower-links-wrapper {
    padding: 40px 0;

    @include mq() {
      padding: 20px 0;
    }

    .corporate-lower-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include mq() {
        border-bottom: 1px solid #dedede;
      }

      .corporate-lower-link {
        width: percentage(360/1160);
        margin-left: percentage(40/1160);

        @include mq() {
          width: 100%;
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
