@charset "utf-8";

#highlight {
  .highlights-list {
    .highlights-item {
      &:nth-child(n+3) {
        @include mq(medium, min, true) {
          margin-top: 75px;
        }
      }

      + .highlights-item {
        @include mq() {
          margin-top: 40px;
        }
      }
    }
  }
}
