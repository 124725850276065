@charset "utf-8";

.c-documentlist {
  .document-detail {
    .detail-title {
      padding: 18px 30px;
      color: #fff;
      background: linear-gradient(to right, $accent-color, $accent-color02);

      @include mq(xxsmall) {
        padding: 18px 15px;
      }

      p {
        padding-left: 43px;
        background: url(/assets/images/common/icons/document.svg) no-repeat;
        background-size: 32px auto;
      }

      .large {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.2;

        @include mq() {
          font-size: 2.2rem;
        }
      }

      .small {
        display: block;
        font-size: 1.3rem;
      }
    }

    .detail-text {
      padding: 20px 30px 0;
      font-size: 1.5rem;

      @include mq(xxsmall) {
        padding: 20px 15px 0;
      }

      ul {
        li + li {
          margin-top: 10px;

          @include mq() {
            margin-top: 25px;
          }
        }
      }

      a {
        display: inline-block;
        padding-left: 30px;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: auto 24px;

        @include mq(medium, min, true) {
          @include ease(color);
        }

        &.pdf {
          background-image: url(/assets/images/common/icons/pdf.svg);
        }

        &.xlsx {
          background-image: url(/assets/images/common/icons/xlsx.svg);
        }

        &.movie {
          background-image: url(/assets/images/common/icons/movie.svg);
        }

        &:hover {
          @include mq(medium, min, true) {
            text-decoration: underline;
            color: $accent-color03;
          }
        }
      }
    }
  }
}
