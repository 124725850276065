@charset "utf-8";

.c-heading-jumbotron {
  position: relative;
  height: 280px;
  padding: 15px 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  @include mq() {
    height: 150px;
  }

  .container {
    height: 100%;
  }

  .heading-title {
    position: relative;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    span {
      position: relative;
      display: inline-block;
      padding: 10px 30px 15px 0;
      font-size: 3.2rem;
      font-weight: bold;
      color: #fff;

      @include mq() {
        padding: 10px 20px 15px 5px;
        font-size: 2.2rem;
      }

      @include mq() {
        font-size: 2rem;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        background-color: $base-color;

        @include mq() {
          min-width: 320px;
        }
      }
    }
  }


  // Slider
  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      .swiper-slide {
        z-index: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }

  // Slider Pagination
  .pagination-wrapper {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 0;
    margin: 0 auto;

    .swiper-pagination {
      right: 0;
      left: 0;
      width: 100%;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 10px;
        opacity: 1;
        outline: none;
        background-color: #fff;

        @include mq(medium, min, true) {
          @include ease(opacity);
        }

        &:hover {
          @include mq(medium, min, true) {
            opacity: 0.7;
          }
        }
      }

      .swiper-pagination-bullet-active {
        transform: scale(1.1);
        background-color: $accent-color03;
      }
    }
  }
}

// 下層のメインビジュアル（IR以外）
.c-heading-jumbotron-lower-main {
  display: flex;
  height: 330px;
  flex-wrap: wrap;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @include mq() {
    height: 150px;
  }

  > .container {
    width: 100%;
  }

  .heading-title {
    width: 100%;
    font-weight: bold;

    .ja {
      display: block;
      font-size: 2.2rem;
      line-height: 1.2;

      @include mq() {
        font-size: 1.6rem;
      }
    }

    .en {
      display: block;
      font-size: 4.8rem;
      line-height: 1.2;

      @include mq() {
        font-size: 3rem;
      }

      svg {
        overflow: initial;
        fill: url(#gradation);

        @include mq() {
          margin-top: -10px;
        }

        @media all and (-ms-high-contrast: none) {
          height: 48px;
        }
      }
    }
  }
}

// type02（短いグラデーション + グレーボーダー）
.c-heading-underline {
  position: relative;
  padding-bottom: 20px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;

  @include mq() {
    font-size: 2.2rem;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
  }

  &:before {
    width: 100%;
    background-color: $gray-dedede;
  }

  &:after {
    width: 260px;
    background: linear-gradient(to right, $accent-color, $accent-color02);

    @include mq() {
      width: 75px;
    }
  }

  // type02（短いグラデーション）
  &--type02 {
    font-size: 3rem;
    text-align: center;

    @include mq() {
      font-size: 2.3rem;
    }

    &:before {
      display: none;
    }

    &:after {
      right: 0;
      width: 40px;
      margin: 0 auto;
    }
  }

  // type03（グレーボーダーのみ）
  &--type03 {
    font-size: 2.2rem;

    @include mq() {
      font-size: 2rem;
    }

    &:before {
      height: 1px;
    }

    &:after {
      display: none;
    }
  }
}
