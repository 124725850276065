@charset "utf-8";

#corporate-information.timeline {
  .timeline-figure {
    padding: 100px 0 120px;

    @include mq() {
      padding: 50px 0 70px;
    }

    .timeline-figure-list {
      position: relative;

      @include mq() {
        padding-left: 16px;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 2px;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
        background-color: #333;

        @include mq() {
          right: auto;
          left: 15px;
          margin-right: 0;
          margin-left: 0;
        }
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 12px;
        display: block;
        width: 8px;
        height: 120px;
        background: #fff url(/assets/images/corporate-information/timeline/dots.png) no-repeat 50% 50%;
        background-size: 8px auto;

        @include mq(medium, min, true) {
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }

      .timeline-figure-item {
        position: relative;
        width: 50%;
        margin-top: -70px;

        @include mq() {
          width: 100%;
          margin-top: 60px;
        }

        .timeline-figure-item-year {
          position: relative;
          z-index: 1;
          margin-bottom: 20px;
          padding-bottom: 25px;
          font-size: 3.6rem;
          font-weight: 600;
          line-height: 1;
          border-bottom: 1px solid #dedede;

          @include mq() {
            margin-bottom: 15px;
            padding-bottom: 18px;
            font-size: 3rem;
          }

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: -1.65rem;
            display: block;
            width: 27px;
            height: 33px;
            background: #fff url(/assets/images/corporate-information/timeline/hexagon.png) no-repeat 50% 100%;
            background-size: 27px auto;

            @include mq() {
              right: auto !important;
              bottom: -1.35rem;
              left: -11px !important;
              width: 22px;
              height: 27px;
              background-size: 22px auto;
            }
          }
        }

        .timeline-figure-item-year-month {
          position: relative;
          z-index: 1;
          margin-bottom: 5px;
          font-size: 1.8rem;
          font-weight: 600;

          @include mq() {
            margin-bottom: 10px;
            font-size: 1.6rem;
          }

          &::before,
          .image {
            content: '';
            position: absolute;
            top: -80px;
            display: block;
            width: 140px;
            height: 140px;

            @include mq(large) {
              top: -60px;
              width: 110px;
              height: 110px;
            }

            @include mq() {
              top: -50px;
              right: 0 !important;
              left: auto !important;
              width: 100px;
              height: 100px;
            }
          }

          .image {
            overflow: hidden;
            border-radius: 50%;
          }

          &:first-of-type {
            &::before {
              @include mq(medium, min, true) {
                background-color: #fff;
              }
            }

            .image {
              top: -90px;

              @include mq() {
                background-color: #fff;
              }

              @include mq(large) {
                top: -75px;
              }

              @include mq() {
                top: -68px;
              }
            }
          }
        }

        .image-large {
          &::before,
          .image {
            top: -90px;
            width: 170px;
            height: 170px;

            @include mq() {
              top: -75px;
              width: 140px;
              height: 140px;
            }
          }

          &:first-of-type {
            .image {
              top: -105px;

              @include mq() {
                top: -85px;
              }
            }
          }
        }

        .timeline-figure-item-text {
          position: relative;
          z-index: 2;
          font-size: 1.5rem;
          line-height: 1.5;

          @include mq() {
            font-size: 1.4rem;
          }

          + .timeline-figure-item-year-month {
            margin-top: 25px;
          }
        }

        .timeline-figure-item-year,
        .timeline-figure-item-year-month,
        .timeline-figure-item-text {
          @include mq() {
            padding-right: 0 !important;
            padding-left: 35px !important;
          }
        }

        &:first-child {
          margin-top: 0;

          .timeline-figure-item-year {
            &::after {
              height: calc(100% + 1.65rem);

              @include mq() {
                height: calc(100% + 1.35rem);
              }
            }
          }
        }

        &:last-child {
          padding-bottom: 215px;

          @include mq() {
            padding-bottom: 140px;
          }
        }

        &:nth-child(odd) {
          margin-left: auto;

          .timeline-figure-item-year {
            &::after {
              left: -1.35rem;
            }
          }

          .timeline-figure-item-year-month {
            &::before,
            .image {
              right: 0;
            }
          }

          .timeline-figure-item-year,
          .timeline-figure-item-year-month,
          .timeline-figure-item-text {
            padding-right: 160px;
            padding-left: 55px;

            @include mq(large) {
              padding-right: 125px;
              padding-left: 40px;
            }
          }
        }

        &:nth-child(even) {
          .timeline-figure-item-year {
            &::after {
              right: -1.35rem;
            }
          }

          .timeline-figure-item-year-month {
            &::before,
            .image {
              left: 0;
            }
          }

          .timeline-figure-item-year,
          .timeline-figure-item-year-month,
          .timeline-figure-item-text {
            padding-right: 60px;
            padding-left: 155px;

            @include mq(large) {
              padding-right: 40px;
              padding-left: 125px;
            }
          }
        }

        &:nth-child(8) {
          @include mq(medium, min, true) {
            margin-top: 30px;
          }
        }

        &:nth-child(14) {
          @include mq(medium, min, true) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
