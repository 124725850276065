@charset "utf-8";

// container
.container {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 40px;

  @include mq(large) {
    padding: 0 20px;
  }

  @include mq() {
    padding: 0 15px;
  }
}

.gradation {
  position: absolute;
  z-index: -9999;
  top: 0;
  left: 0;
}

// section
.section {
  padding: 60px 0;

  @include mq() {
    padding: 40px 0;
  }

  &.bg-gray {
    background-color: $gray-f5f5f5;
  }
}

// display
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@include mq(medium, min, true) {
  .sp-only {
    display: none !important;
  }
}

@include mq() {
  .pc-only {
    display: none !important;
  }
}

// font
.barlow {
  font-family: $font-barlow;
}

// font-weight
.bold {
  font-weight: bold !important;
}

.medium {
  font-weight: 500 !important;
}

.regular {
  font-weight: normal !important;
}

// text
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

// overflow
.visible {
  overflow: visible !important;
}

.hidden {
  overflow: hidden !important;
}

.scroll {
  overflow: scroll !important;
}

.auto {
  overflow: auto !important;
}

// margin, padding
$min: 0;
$max: 80;

.ma {
  margin-right: auto;
  margin-left: auto;
}

@for $i from $min through $max {
  .m#{$i} {
    margin: #{$i}px!important;
  }
  .mt#{$i} {
    margin-top: #{$i}px!important;
  }
  .mr#{$i} {
    margin-right: #{$i}px!important;
  }
  .mb#{$i} {
    margin-bottom: #{$i}px!important;
  }
  .ml#{$i} {
    margin-left: #{$i}px!important;
  }
  .mtb#{$i} {
    margin-top: #{$i}px!important;
    margin-bottom: #{$i}px!important;
  }
  .mlr#{$i} {
    margin-right: #{$i}px!important;
    margin-left: #{$i}px!important;
  }

  .p#{$i} {
    padding: #{$i}px!important;
  }
  .pt#{$i} {
    padding-top: #{$i}px!important;
  }
  .pr#{$i} {
    padding-right: #{$i}px!important;
  }
  .pb#{$i} {
    padding-bottom: #{$i}px!important;
  }
  .pl#{$i} {
    padding-left: #{$i}px!important;
  }
  .ptb#{$i} {
    padding-top: #{$i}px!important;
    padding-bottom: #{$i}px!important;
  }
  .plr#{$i} {
    padding-right: #{$i}px!important;
    padding-left: #{$i}px!important;
  }
}

.scroll-x {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  // スクロールバーの軌道
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
  }

  // スクロールバーの動く部分
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(50, 50, 50, .5);
    box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
  }
}

.accent-color03{
  color: $accent-color03;
}
