@charset "utf-8";

#corporate-information {
  .company-profiles-links {
    display: flex;
    margin-bottom: -(percentage(10/1160));
    padding-top: 50px;
    flex-wrap: wrap;

    @include mq() {
      margin-bottom: -(percentage(5/345));
      justify-content: space-between;
      padding-top: 50px;
    }

    .company-profiles-link {
      width: percentage(224/1160);
      margin-bottom: percentage(10/1160);
      margin-left: percentage(10/1160);

      @include mq() {
        width: percentage(170/345);
        margin-bottom: percentage(5/345);
        margin-left: 0;
      }

      &:nth-child(5n+1) {
        margin-left: 0;
      }

      a {
        display: flex;
        height: 56px;
        transition: opacity 0.2s ease;
        text-align: center;
        text-decoration: none;
        border: 1px solid #dedede;
        align-items: center;

        @include mq(large) {
          font-size: 1.3rem;
          line-height: 1.2;
        }

        @include mq() {
          height: 48px;
        }

        span {
          width: 100%;
        }

        &:hover {
          @include mq(medium, min, true) {
            opacity: 0.7;
          }
        }
      }

      &.is-active {
        a {
          color: #fff;
          background: linear-gradient(to right, $accent-color, $accent-color02);

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .company-profiles {
    padding-top: 60px;
    padding-bottom: 120px;

    @include mq() {
      padding-top: 45px;
      padding-bottom: 70px;
    }
  }

  .company-profiles-table {
    table {
      width: 100%;

      @include mq() {
        display: block;
      }

      tbody,
      tr,
      th,
      td {
        @include mq() {
          display: block;
          width: 100% !important;
        }
      }

      tr {
        &:first-child {
          th {
            padding-bottom: 20px;
            font-size: 2.2rem;
            font-weight: bold;
            border-bottom: 2px solid #333;

            @include mq() {
              padding-bottom: 15px;
              font-size: 2rem;
            }
          }
        }

        &:not(:first-child) {
          th,
          td {
            padding: 20px 0;
            font-size: 1.5rem;
            vertical-align: top;
            border-bottom: 1px solid #dedede;

            @include mq() {
              padding: 0;
              border-bottom: 0;
            }
          }

          th {
            width: 228px;
            padding-right: 20px;

            @include mq() {
              padding-top: 15px;
              padding-right: 0;
            }
          }

          td {
            width: calc(100% - 228px);

            @include mq() {
              padding: 5px 0 15px;
              border-bottom: 1px solid #dedede;
            }

            a {
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .company-download {
    margin: 50px auto 0;

    .download-text {
      font-size: 1.5rem;
      text-align: center;
    }

    .c-button {
      max-width: 292px;
      margin: 30px auto 0;
    }
  }

  .company-access {
    margin-top: 120px;

    @include mq() {
      margin-top: 70px;
    }

    .company-access-address {
      margin: 25px 0;
      font-size: 1.5rem;
      line-height: 2;
      text-align: center;

      @include mq() {
        margin-bottom: 20px;
      }

      .c-title--sub {
        margin-bottom: 15px;
      }

      .note {
        display: block;
        font-size: 1.3rem;
      }
    }

    .company-access-map {
      position: relative;
      padding-top: percentage(520/1160);

      iframe {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
