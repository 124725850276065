@charset "utf-8";

.l-message {
  padding: 0;

  @include mq() {
    padding: 30px 0 65px;
  }

  .container {
    max-width: 1080px;

    @include mq(medium, min, true) {
      padding-left: 0;
    }
  }

  .message-box {
    width: 100%;

    @include mq(medium, min, true) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .box-left {
      @include mq(medium, min, true) {
        width: percentage(570/980);
      }
    }

    .box-right {
      @include mq(medium, min, true) {
        width: percentage(364/980);
      }
    }
  }

  .message-image {
    @include mq() {
      margin-bottom: 30px;
    }
  }

  .message-detail {
    margin-top: 30px;

    @include mq() {
      margin-top: 25px;
    }

    .detail-title {
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.5;

      @include mq() {
        font-size: 2rem;
      }
    }

    .detail-text {
      margin-top: 15px;
      font-size: 1.6rem;
    }
  }

  .message-link {
    margin-top: 25px;
  }
}
