@charset "utf-8";

#index {
  .index-c-container {
    position: relative;
    max-width: 1680px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;

    @include mq() {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .index-c-section {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      display: block;
      width: percentage(1560/1680);
      height: 470px;

      @include mq(xlarge) {
        width: 100%;
      }

      @include mq() {
        width: 100%;
        height: 0;
        padding-top: percentage(346/375);
      }
    }

    > * {
      position: relative;
      z-index: 1;
    }
  }

  .index-c-title {
    margin-bottom: 25px;
  }

  .index-c-section-heading-wrapper {
    position: relative;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-right: 120px;
    padding-left: 120px;
    color: #fff;

    @include mq(xlarge) {
      padding-right: 40px;
      padding-left: 40px;
    }

    @include mq() {
      padding-top: 30px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .index-c-section-heading-title {
      margin-bottom: 10px;
      font-weight: bold;

      @include mq() {
        margin-bottom: 15px;
      }

      em {
        font-size: 4.8rem;
        font-style: normal;
        vertical-align: middle;

        @include mq() {
          font-size: 3.2rem;
        }
      }

      span {
        margin-left: 20px;
        font-size: 1.8rem;
        vertical-align: middle;

        @include mq() {
          display: block;
          margin-left: 0;
          font-size: 1.6rem;
        }
      }
    }

    .index-c-section-heading-text {
      font-size: 1.5rem;
      line-height: 1.8;

      @include mq() {
        font-size: 1.4rem;
        line-height: 1.75;
      }
    }
  }

  // メインビジュアル
  .jumbotron {
    position: relative;
    z-index: 1;
    height: 720px;

    @include mq() {
      height: 485px;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 306px;
      background-image: linear-gradient($accent-color, $accent-color02);

      @include mq() {
        height: 110px;
      }
    }

    .jumbotron-visual {
      position: relative;
      z-index: 2;
      width: percentage(1480/1680);
      height: 640px;
      margin-left: auto;
      background: #f2f2f2 url(/assets/images/index/jumbotron.jpg) no-repeat 100% 0;
      background-size: cover;

      @include mq() {
        width: percentage(330/375);
        height: 100%;
        padding: 0;
        background-image: url(/assets/images/index/jumbotron-sp.jpg);
      }

      .jumbotron-visual-copy {
        position: absolute;
        z-index: 1;
        top: 80px;
        left: -70px;
        font-weight: bold;

        @include mq() {
          top: 40px;
          left: -25px;
        }

        .jumbotron-visual-copy-title {
          margin-bottom: 15px;
          font-size: 5.6rem;
          line-height: 1.2;

          @include mq() {
            margin-bottom: 20px;
            font-size: 3.2rem;
          }
        }

        .jumbotron-visual-copy-text {
          margin-bottom: 25px;
          font-size: 1.6rem;
          line-height: 2;
          letter-spacing: 1px;

          @include mq() {
            padding-right: 25px;
            font-size: 1.3rem;
          }
        }

        .jumbotron-visual-copy-button {
          @include mq() {
            max-width: 180px;
          }

          .c-button-guide {
            a {
              margin: 0;

              @include mq() {
                height: 42px;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }

  // ニュースとIR
  .news-ir-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    width: percentage(1400/1680);
    margin: -200px 0 55px auto;
    padding: 70px 40px 70px 60px;
    flex-wrap: wrap;
    background-color: #fff;
    justify-content: space-between;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    @include mq(large) {
      width: 100%;
      padding-left: 40px;
    }

    @include mq() {
      width: 100%;
      margin-top: 0;
      padding: 0;
    }

    .news-wrapper {
      width: calc(100% - 360px - 60px);

      @include mq(xlarge) {
        width: calc(100% - 250px - 40px);
      }

      @include mq() {
        width: 100%;
        padding: 20px 15px;
      }

      .news-category {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #dedede;
        flex-wrap: wrap;

        @include mq() {
          padding: 15px 0;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }

        .news-category-title-wrapper {
          width: 180px;
          padding-right: 24px;

          @include mq() {
            width: 100%;
            padding-right: 0;
          }

          .news-category-title {
            margin-bottom: 10px;
            font-size: 1.8rem;
            font-weight: bold;

            @include mq() {
              font-size: 1.6rem;
            }

            .important {
              display: inline-block;
              padding-right: 30px;
              background: url(/assets/images/common/icons/attention.svg) no-repeat center right;
              background-size: 26px auto;
            }
          }

          .news-category-title-link {
            @include mq() {
              margin-bottom: 15px;
            }

            a {
              font-weight: normal;

              @include mq() {
                font-size: 1.4rem;
              }
            }
          }
        }

        .news-category-articles {
          width: calc(100% - 180px);

          @include mq() {
            width: 100%;
          }

          .c-newslist {
            .news-list {
              .news-item {
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: 0;

                + .news-item {
                  margin-top: 15px;
                }
              }
            }

            .ir_list {
              .news-item {
                &:nth-child(n+3) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .ir-wrapper {
      width: 360px;

      @include mq(xlarge) {
        width: 250px;
      }

      @include mq() {
        width: 100%;
        margin-bottom: 0;
        padding: 20px 15px 55px;
      }

      .ir-title {
        margin-bottom: 10px;
        font-size: 2.2rem;
        font-weight: bold;

        @include mq() {
          font-size: 2rem;
        }
      }

      .ir-slider {
        position: relative;

        .swiper-pagination {
          right: 0;
          left: 0;
          margin-top: 10px;
          transform: none;

          .swiper-pagination-bullet {
            margin: 0 8px;
            outline: none;

            &.swiper-pagination-bullet-active {
              background-color: $accent-color03;
            }
          }
        }
      }
    }
  }

  .menu-large-wrapper {
    margin-bottom: 55px;

    @include mq() {
      margin-bottom: 45px;
    }

    .index-c-container {
      @include mq() {
        padding: 0;
      }
    }

    .menu-large-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .menu-large-item {
      width: calc(50% - 3px);

      @include mq(large) {
        width: 100%;
      }

      + .menu-large-item {
        @include mq() {
          margin-top: 30px;
        }
      }

      .image {
        @include mq() {
          position: relative;
          z-index: 1;
        }
      }

      .text-wrapper {
        position: relative;
        width: percentage(735/795);
        margin-top: -37px;
        margin-left: auto;
        padding: 30px;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        @include mq() {
          width: 100%;
          margin-top: 0;
          padding: 15px;
          padding-bottom: 30px;
        }

        .title {
          margin-bottom: 10px;
          font-size: 3rem;
          font-weight: bold;

          @include mq() {
            font-size: 2.7rem;
          }
        }

        .text {
          margin-bottom: 20px;
          font-size: 1.5rem;
          line-height: 2;

          @include mq() {
            font-size: 1.4rem;
          }
        }

        .link {
          text-align: right;

          .c-textlink {
            svg {
              text {
                text-decoration: none;
              }
            }
          }
        }

        .links-list {
          display: flex;
          margin: 0 -17px;
          flex-wrap: wrap;

          @include mq() {
            margin: 0 -10px -10px;
          }

          .links-item {
            position: relative;
            z-index: 2;
            margin: 0 17px;
            line-height: 2;

            @include mq() {
              margin: 0 10px 10px;
            }

            a {
              font-weight: normal;
            }
          }
        }
      }

      .parent-link {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        opacity: 0;
        background-color: #fff;

        @include mq(medium, min, true) {
          @include ease(opacity);
        }

        &:hover {
          @include mq(medium, min, true) {
            opacity: 0.5;
          }
        }
      }
    }
  }

  // BUSINESS 事業内容
  .business {
    margin-bottom: 60px;

    @include mq(medium, min, true) {
      margin-top: 75px;
    }

    &::before {
      right: 0;
      background-image: linear-gradient(to Right, $accent-color, $accent-color02);

      @include mq() {
        height: 600px;
        padding-top: 0;
      }
    }

    .business-copy {
      margin-bottom: 20px;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.5;

      @include mq() {
        font-size: 2rem;
      }
    }

    .index-c-section-heading-title {
      span {
        @include mq() {
          display: inline-block;
          margin-left: 15px;
        }
      }
    }

    .index-c-section-heading-text {
      max-width: 560px;

      @include mq(mega) {
        max-width: 100%;
      }
    }

    .business-mock {
      @include mq(medium, min, true) {
        position: absolute;
        z-index: 1;
        top: -42px;
        right: 120px;
        width: 518px;
      }

      @include mq(mega) {
        right: 40px;
        width: 350px;
      }

      @include mq() {
        width: auto;
        max-width: 350px;
        margin: 20px auto 0;
      }
    }

    .business-list-wrapper {
      position: relative;

      @include mq(mega) {
        padding: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: 10.5vw;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        background-image: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        @include mq(mega, min, true) {
          display: none;
        }

        @include mq() {
          top: 28vw;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          display: block;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%) rotate(45deg);
          border-style: solid;
          border-color: $accent-color;
        }
      }

      .swiper-button-prev {
        left: 31vw;

        @include mq() {
          left: 3vw;
        }

        &::after {
          border-width: 0 0 2px 2px;
        }
      }

      .swiper-button-next {
        right: 31vw;

        @include mq() {
          right: 3vw;
        }

        &::after {
          border-width: 2px 2px 0 0;
        }
      }

      .swiper-pagination {
        right: 0;
        bottom: -24px;
        left: 0;
        margin-top: 10px;
        transform: none;

        @include mq(mega, min, true) {
          display: none;
        }

        .swiper-pagination-bullet {
          margin: 0 8px;
          outline: none;

          &.swiper-pagination-bullet-active {
            background-color: $accent-color03;
          }
        }
      }
    }

    .business-list {
      margin-top: 45px;

      @include mq(mega, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      @include mq() {
        margin-top: 30px;
      }

      .business-item {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        @include mq(mega, min, true) {
          width: percentage(312/1600);
          margin-left: percentage(10/1600);
        }

        @include mq() {
          width: 100%;
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          display: block;
          text-decoration: none;

          &:hover {
            svg,
            img {
              opacity: 1;
            }

            svg {
              text {
                @include mq(medium, min, true) {
                  text-decoration: underline;
                }
              }
            }

            .thumb {
              img {
                @include mq(medium, min, true) {
                  transform: scale(1.2);
                }
              }
            }
          }
        }

        .thumb {
          overflow: hidden;

          img {
            width: 100%;

            @include mq(medium, min, true) {
              @include ease(transform);
            }
          }
        }

        .text-wrapper {
          padding: 20px 20px 60px;
          background-color: #fff;

          .title-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .mark {
              width: 40px;

              @include mq() {
                width: 36px;
              }
            }

            .text {
              width: calc(100% - 40px);
              padding-left: 10px;

              @include mq() {
                width: calc(100% - 36px);
              }

              em {
                display: block;
                margin-bottom: 5px;
                font-size: 2.4rem;
                font-weight: 600;
                font-style: normal;
                line-height: 1;

                @include mq() {
                  margin-bottom: 4px;
                  font-size: 2rem;
                }
              }

              span {
                display: block;
                font-size: 1.2rem;
                line-height: 1.3333;

                @include mq() {
                  font-size: 1.3rem;
                }
              }
            }
          }

          .description {
            margin-top: 15px;
            line-height: 1.75;


            @include mq() {
              font-size: 1.4rem;
            }
          }

          .link {
            position: absolute;
            right: 20px;
            bottom: 20px;
          }
        }
      }
    }
  }

  // GLOBAL INOVATION 世界をつなぐBEENOSグループの取り組みについて
  .global-inovation {
    position: relative;
    margin-bottom: 60px;
    padding-top: 110px;

    @include mq() {
      margin-bottom: 45px;
      padding-top: 30px;
    }

    .container {
      @include mq(medium, min, true) {
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 640px;
      background: url(/assets/images/index/global-inovation-bg.jpg) no-repeat center center;
      background-size: cover;

      @include mq() {
        height: 320px;
      }

      @include mq(xsmall) {
        height: 370px;
      }
    }

    .global-inovation-heading {
      padding-bottom: 124px;
      color: #fff;
      text-shadow: 0 0 5px rgba(#000, 0.8);

      @include mq() {
        padding-bottom: 10px;
      }

      .global-inovation-heading-title {
        margin-bottom: 25px;
        font-weight: bold;

        em {
          font-size: 4.8rem;
          font-style: normal;
          line-height: 1.5;

          @include mq() {
            font-size: 3.2rem;
          }
        }

        span {
          display: block;
          font-size: 1.8rem;

          @include mq() {
            font-size: 1.6rem;
          }
        }
      }

      .global-inovation-heading-text {
        max-width: 460px;
        font-size: 1.5rem;
      }
    }

    .global-inovation-contents {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      @include mq() {
        margin-top: 30px;
      }

      a {
        .icon,
        .text {
          @include mq(medium, min, true) {
            @include ease(opacity);
          }
        }

        &:hover {
          .icon,
          .text {
            @include mq(medium, min, true) {
              opacity: 0.7;
            }
          }
        }
      }

      .global-inovation-contents-item {
        padding: 40px 20px 30px;
        text-decoration: none;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        @include mq(medium, min, true) {
          width: percentage(280/1160);
        }

        @include mq() {
          display: block;
          padding: 30px 20px;
        }

        &:not(:nth-child(4n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(10/1160);
          }
        }

        + .global-inovation-contents-item {
          @include mq() {
            margin-top: 10px;
          }
        }

        .icon {
          max-width: 80px;
          margin: 0 auto 10px;
          opacity: 1;
        }

        .text {
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;

          @include mq(large) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .menu-medium-wrapper {
    margin: 60px 0;

    @include mq() {
      margin: 45px 0;
    }

    .index-c-container {
      padding: 0;
    }

    .menu-medium-list {
      display: flex;
      flex-wrap: wrap;
    }

    .menu-medium-item {
      width: percentage(526/1598);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      @include mq() {
        width: 100%;
      }

      &:not(:nth-child(3n)) {
        @include mq(medium, min, true) {
          margin-right: percentage(10/1598);
        }
      }

      + .menu-medium-item {
        @include mq() {
          margin-top: 10px;
        }
      }

      > a {
        position: relative;
        display: block;
        transition: color 0.2s ease;
        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          transition: opacity 0.2s ease;
          opacity: 0;
          background-image: linear-gradient(to Right, $accent-color, $accent-color02);
        }

        .image {
          position: relative;
          z-index: 1;

          img {
            width: 100%;
          }
        }

        .text-wrapper {
          position: relative;
          z-index: 1;
          padding: 30px;

          @include mq(xlarge) {
            @include mq(medium, min, true) {
              padding: 30px 20px;
            }
          }

          .title {
            font-size: 2.2rem;
            font-weight: bold;

            @include mq() {
              font-size: 2rem;
            }

            span {
              display: block;
              font-size: 1.5rem;

              @include mq() {
                font-size: 1.4rem;
              }
            }
          }

          .link-text {
            margin-top: 30px;
            text-align: right;

            @include mq() {
              margin-top: 20px;
            }
          }
        }

        &:hover {
          @include mq(medium, min, true) {
            color: #fff;
          }

          &::before {
            @include mq(medium, min, true) {
              opacity: 1;
            }
          }

          .image {
            img {
              opacity: 1;
            }
          }

          .text-wrapper {
            .link-text {
              .c-textlink {
                @include mq(medium, min, true) {
                  pointer-events: none;
                }

                span {
                  &::after {
                    @include mq(medium, min, true) {
                      background-position: center right;
                    }
                  }

                  svg {
                    opacity: 1;

                    @include mq(medium, min, true) {
                      fill: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // USECASE 事例・取り組み
  .usecase {
    margin-bottom: 80px;

    @include mq() {
      margin-bottom: 60px;
    }

    &::before {
      right: 0;
      background-color: #f5f5f5;

      @include mq(medium, min, true) {
        height: 274px;
      }
    }

    .index-c-section-heading-wrapper {
      color: $base-text-color;
    }

    .usecase-slider-wrapper {
      margin-top: 30px;

      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        background-image: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          display: block;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%) rotate(45deg);
          border-style: solid;
          border-color: $accent-color;
        }
      }

      .swiper-button-prev {
        left: 4.5vw;

        @include mq(medium, min, true) {
          @include mq(mega) {
            left: 30vw;
          }
          @include mq(large) {
            left: 25vw;
          }
        }

        &::after {
          border-width: 0 0 2px 2px;
        }
      }

      .swiper-button-next {
        right: 4.5vw;

        @include mq(medium, min, true) {
          @include mq(mega) {
            right: 30vw;
          }
          @include mq(large) {
            right: 25vw;
          }
        }

        &::after {
          border-width: 2px 2px 0 0;
        }
      }

      .swiper-slide {
        padding: 0 20px;
      }

      .usecase-slider-content {
        display: flex;
        text-decoration: none;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        align-items: center;

        &:hover {
          svg,
          img {
            opacity: 1;
          }

          svg {
            text {
              @include mq(medium, min, true) {
                text-decoration: underline;
              }
            }
          }

          .image {
            img {
              @include mq(medium, min, true) {
                transform: scale(1.1);
              }
            }
          }
        }

        .image {
          overflow: hidden;
          width: percentage(530/1408);

          @include mq(mega) {
            width: 100%;
          }

          img {
            width: 100%;

            @include mq(medium, min, true) {
              @include ease(transform);
            }
          }
        }

        .text-banner-wrapper {
          display: flex;
          width: percentage(878/1408);
          padding: 15px 40px 15px 0;
          flex-wrap: wrap;

          @include mq(mega) {
            width: 100%;
            padding: 40px;
          }

          @include mq() {
            padding: 30px;
          }

          .text-wrapper {
            width: calc(100% - 220px);
            padding: 0 40px;

            @include mq(mega) {
              width: 100%;
              padding: 0;
              order: 2;
            }

            .date-marks-wrapper {
              display: flex;
              margin-bottom: 10px;

              .date {
                font-family: $font-barlow;
                font-size: 1.4rem;
                font-weight: 600;
                color: #9e9e9e;
              }

              .marks {
                display: flex;
                margin-left: 10px;
                flex-wrap: wrap;

                .mark {
                  margin-right: 10px;
                  font-size: 1.4rem;
                }
              }
            }

            .title {
              margin-bottom: 5px;
              font-size: 2rem;
              font-weight: bold;
              line-height: 1.5;

              @include mq() {
                margin-bottom: 15px;
                font-size: 1.6rem;
              }
            }

            .description {
              font-size: 1.5rem;
              line-height: 1.5;
            }

            .link {
              margin-top: 10px;
              text-align: right;

              @include mq() {
                margin-top: 20px;
              }
            }
          }

          .bnr {
            width: 220px;

            @include mq(mega) {
              width: 100%;
              max-width: 180px;
              margin: 0 auto 40px;
              text-align: center;
              order: 1;
            }

            @include mq() {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
