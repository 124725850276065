@charset "utf-8";

#corporate-information {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .corporate-lower-links-wrapper {
    padding: 40px 0;

    @include mq() {
      padding: 20px 0;
    }

    .corporate-lower-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include mq() {
        border-bottom: 1px solid #dedede;
      }

      .corporate-lower-link {
        width: percentage(360/1160);
        margin-left: percentage(40/1160);

        @include mq() {
          width: 100%;
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  // 数字で見るBEENOS
  // ----------------------------------------------------------------------------------------------------------------
  .numbers {
    margin-top: 15px;
    margin-bottom: 60px;

    .numbers-block {
      margin-top: 25px;
      padding: 65px 0 110px;
      background: url(/assets/images/corporate-information/index/numbers-bg.jpg) no-repeat center center;
      background-size: cover;

      @include mq() {
        padding: 60px 0 90px;
        background-image: url(/assets/images/corporate-information/index/numbers-bg-sp.jpg);
      }

      .container {
        @include mq(large) {
          padding: 0 30px;
        }

        @include mq(xxsmall) {
          padding: 0 15px;
        }
      }

      .numbers-box-list-wraper {
        position: relative;

        .swiper-wrapper {
          @include mq(large, min, true) {
            display: block;
          }
        }

        .swiper-pagination {
          bottom: -25px;

          @include mq(large, min, true) {
            display: none;
          }

          .swiper-pagination-bullet {
            opacity: 0.3;
            outline: none;
            background-color: #fff;
          }

          .swiper-pagination-bullet-active {
            opacity: 1;
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          top: 18px;
          outline: none;
          background: none;

          @include mq(large, min, true) {
            display: none;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 14px;
            height: 20px;
            margin: auto;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center right;
            background-size: auto 20px;
          }
        }

        .swiper-button-prev {
          left: 0;

          &:after {
            transform: rotate(180deg);
          }
        }

        .swiper-button-next {
          right: 0;
        }
      }

      .numbers-box {
        color: #fff;

        + .numbers-box {
          @include mq(large, min, true) {
            padding-top: 60px;
            border-top: 1px solid rgba(#fff, 0.5);
          }
        }

        &.global {
          .numbers-box-title {
            @include mq() {
              margin-top: -10px;
            }
          }

          .numbers-box-list {
            @include mq(large) {
              margin-top: 75px;
            }

            @include mq() {
              margin-top: 52px;
            }
          }
        }

        .numbers-box-title {
          margin-bottom: 20px;
          font-size: 2.6rem;
          font-weight: normal;
          line-height: 1.5;
          text-align: center;

          @include mq(large) {
            font-size: 2.2rem;
          }
        }

        .numbers-box-sub {
          margin-bottom: 10px;
          font-size: 2.2rem;
          font-weight: normal;

          @include mq(large) {
            font-size: 1.8rem;
          }

          em {
            font-size: 1.2rem;
            font-weight: bold;
            font-style: normal;
          }
        }

        .numbers-box-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @include mq(large) {
            margin-top: -15px;
          }

          .numbers-item {
            position: relative;

            @include mq(large) {
              width: percentage(150/315);
              margin: 15px 0;
            }

            &:before,
            &:after {
              content: "";
              position: absolute;
              background-color: rgba(#fff, 0.5);
            }

            &:not(:nth-child(2n)) {
              &:after {
                @include mq(large) {
                  top: 0;
                  right: - percentage(20/360);
                  width: 1px;
                  height: 100%;
                }
              }
            }

            &:nth-child(n+3) {
              &:before {
                @include mq(large) {
                  top: -15px;
                  left: 0;
                  width: 100%;
                  height: 1px;
                }
              }
            }

            .numbers-detail {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 180px;

              @include mq(large) {
                height: 100px;
              }

              &.en {

                .detail-title {
                  width: 105%;
                  margin-left: -3%;
                  transform: scale(0.95);
                }

                .detail-text {
                  width: 125%;
                  margin-left: -12%;
                  transform: scale(0.75);

                  .unit {
                    display: inline-block;
                    margin: 0 10px;

                    @include mq(large) {
                      margin: 0 5px;
                    }
                  }

                  .note {
                    bottom: -25px;
                  }
                }
              }

              .detail-title {
                display: flex;
                align-items: center;
                font-size: 1.6rem;

                @include mq(large) {
                  margin-bottom: 5px;
                  font-size: 1.2rem;
                }

                @include mq(xxsmall) {
                  font-size: 1.1rem;
                }

                span {
                  display: inline-block;
                  vertical-align: middle;
                }

                .icon {
                  flex-shrink: 0;
                  max-width: 48px;
                  margin-right: 10px;

                  @include mq(large) {
                    max-width: 29px;
                    margin-right: 5px;
                  }

                  @include mq(xxsmall) {
                    max-width: 25px;
                  }
                }
              }

              .detail-text {
                text-align: center;

                p {
                  + p {
                    margin-top: 20px;

                    @include mq(large) {
                      margin-top: 10px;
                    }
                  }
                }

                .price,
                .year,
                .number {
                  line-height: 1;

                  .num {
                    font-family: $font-barlow;
                  }

                  .unit {
                    display: inline-block;
                    margin-left: 10px;

                    @include mq(large) {
                      margin-left: 5px;
                    }
                  }
                }

                .price {
                  .num {
                    font-size: 8rem;

                    @include mq(large) {
                      font-size: 3.6rem;
                    }
                  }

                  .unit {
                    font-size: 4rem;

                    @include mq(large) {
                      font-size: 1.8rem;
                    }
                  }
                }

                .year {
                  .num {
                    font-size: 4rem;

                    @include mq(large) {
                      font-size: 2.4rem;
                    }
                  }

                  .unit {
                    font-size: 2rem;

                    @include mq(large) {
                      font-size: 1.2rem;
                    }
                  }
                }

                .number {
                  .num {
                    font-size: 6.2rem;

                    @include mq(large) {
                      font-size: 3.6rem;
                    }
                  }

                  .unit {
                    font-size: 3.1rem;

                    @include mq(large) {
                      font-size: 1.8rem;
                    }
                  }
                }

                .note {
                  position: absolute;
                  right: 0;
                  bottom: -5px;
                  left: 0;
                  font-size: 1.2rem;
                }
              }
            }
          }

          &:not(.numbers-box-list--col4) {
            @include mq(large, min, true) {
              margin-top: -20px;
            }

            .numbers-item {
              @include mq(large, min, true) {
                width: percentage(360/1160);
                margin: 20px 0;
              }

              &:not(:nth-child(3n)) {
                &:after {
                  @include mq(large, min, true) {
                    top: 0;
                    right: - percentage(20/360);
                    width: 1px;
                    height: 100%;
                  }
                }
              }

              &:nth-child(n+4) {
                &:before {
                  @include mq(large, min, true) {
                    top: -20px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                  }
                }
              }
            }
          }

          &--col4 {
            .numbers-item {
              @include mq(large, min, true) {
                width: percentage(260/1160);
              }

              &:not(:nth-child(4n)) {
                &:after {
                  @include mq(large, min, true) {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: - percentage(20/260);
                    width: 1px;
                    height: 100%;
                    background-color: rgba(#fff, 0.5);
                  }
                }
              }

              .numbers-detail {
                @include mq(large, min, true) {
                  height: 156px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// MESSAGE
// ----------------------------------------------------------------------------------------------------------------
.message {
  .messages {
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;

    .messages-content {
      display: flex;
      width: 50%;
      min-height: 470px;
      padding: 30px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      align-items: center;

      @include mq() {
        width: 100%;
        height: auto;
        min-height: 310px;
        padding: 40px 15px;
      }

      .messages-content-inner {
        width: 100%;
      }

      &:nth-child(1) {
        background-image: url(/assets/images/corporate-information/index/index-image-001.jpg);
        background-position: 0 0;

        @include mq() {
          order: 1;
        }
      }

      &:nth-child(2) {
        background-image: linear-gradient(-45deg, $accent-color02, $accent-color);

        @include mq() {
          order: 2;
        }
      }

      &:nth-child(3) {
        background-image: linear-gradient(-45deg, #1a1a1a, #4c4c4c);

        @include mq() {
          order: 4;
        }
      }

      &:nth-child(4) {
        background-image: url(/assets/images/corporate-information/index/index-image-002.jpg);

        @include mq() {
          order: 3;
        }
      }

      &:nth-child(5) {
        background-image: url(/assets/images/corporate-information/index/index-image-003.jpg);
        background-size: 100% auto;

        @include mq() {
          order: 5;
        }

        &.en {
          background-image: url(/assets/images/corporate-information/index/index-image-003_en.jpg);
        }
      }

      &:nth-child(6) {
        background-image: linear-gradient(-45deg, $accent-color02, $accent-color);

        @include mq() {
          order: 6;
        }
      }

      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(5) {
        @include mq() {
          height: auto;
          min-height: 0;
          padding: 0;
          padding-top: percentage(211/375);
          background-size: 100%;
        }
      }

      .messages-content-text {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.75;
        text-align: center;
        color: #fff;

        @include mq() {
          font-size: 2rem;
        }

        .sign {
          display: block;
          margin-top: 18px;
          font-size: 1.6rem;
          font-weight: normal;

          @include mq() {
            margin-top: 15px;
            font-size: 1.5rem;
          }
        }
      }

      .messages-content-button {
        margin-top: 30px;

        @include mq() {
          margin-top: 25px;
        }
      }
    }
  }
}
