@charset "utf-8";

#presentations {
  .presentations-nav {
    margin-bottom: 60px;

    @include mq() {
      margin-bottom: 40px;
    }
  }

  .presentations-title {
    margin-bottom: 20px;
  }
}
