@charset "utf-8";

#press-release-detail {
  // 新デザイン
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .main-block {
    font-size: 1.6rem;

    .fb-like {
      max-width: 150px;
      flex-shrink: 0;
    }

    // 記事タイトル部分
    .main-title {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 40px;
      }

      .title-box {
        display: flex;

        .box-left {
          width: 200px;
          margin-right: 60px;
          flex-shrink: 0;

          @include mq(large) {
            margin-right: 30px;
          }

          img {
            border: 1px solid $gray-dedede;
          }
        }

        .box-right {
          flex: 1;
        }
      }

      .title-detail {
        .detail-date {
          margin-bottom: 10px;

          @include mq() {
            margin-bottom: 15px;
          }

          .date,
          .category {
            display: inline-block;
          }

          .date {
            margin-right: 10px;
            font-family: $font-barlow;
            font-size: 1.6rem;
            font-weight: 500;
            color: $gray-808080;
          }

          .category {
            padding: 5px 8px;
            font-size: 1.2rem;
            line-height: 1;
            color: #2c86b8;
            border: 1px solid #2c86b8;
          }
        }

        .detail-title {
          margin-bottom: 30px;

          @include mq() {
            margin-bottom: 35px;
          }

          .large,
          .small {
            display: block;
          }

          .large {
            margin-bottom: 15px;
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;

            @include mq() {
              font-size: 2rem;
            }
          }

          .small {
            font-size: 1.6rem;
            font-weight: normal;
          }
        }
      }

      .title-share {
        display: flex;
        justify-content: flex-end;
      }
    }

    // 記事コンテンツ部分
    .main-content {
      margin-bottom: 60px;

      @include mq() {
        margin-bottom: 40px;
      }

      a {
        color: $accent-color03;
      }

      .content-box {
        @include pressEdit(); // mixinで設定
      }

      // 関連リンク
      .content-link {
        margin-top: 45px;

        @include mq() {
          margin-top: 25px;
        }

        .link-detail {
          padding: 20px 30px;
          border: 1px solid $gray-dedede;

          @include mq() {
            padding: 10px 15px 15px;
          }

          .detail-title {
            margin-bottom: 10px;
            font-size: 2rem;
            font-weight: bold;

            @include mq() {
              font-size: 1.8rem;
            }
          }

          .detail-text {
            font-size: 1.5rem;
            color: $accent-color03;

            .link-list {
              .link-item {
                padding-left: 1em;
                text-indent: -1em;

                + .link-item {
                  margin-top: 5px;
                }

                &:before {
                  content: "・";
                }
              }
            }
          }

          .detail-contact {
            margin-top: 30px;
            padding: 20px;
            font-size: 1.5rem;
            background-color: $gray-f5f5f5;

            @include mq() {
              margin-top: 10px;
              padding: 10px 15px 15px;
            }

            .contact-title {
              margin-bottom: 5px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  // 一覧導線ボタン 〜 最新のトピック
  .bottom-block {
    .bottom-button {
      margin: 0 auto 120px;

      @include mq(medium, min, true) {
        max-width: 345px;
      }

      @include mq() {
        margin-bottom: 70px;
      }

      .c-button {
        max-width: 100%;
      }
    }

    .button-topics {
      .topics-title {
        margin-bottom: 30px;

        .title {
          padding-bottom: 20px;
          font-size: 2.2rem;
          font-weight: bold;
          border-bottom: 1px solid $gray-333;

          @include mq() {
            padding-bottom: 10px;
            font-size: 2rem;
          }
        }
      }

      .topics-list {
        @include mq(medium, min, true) {
          display: flex;
          flex-wrap: wrap;
        }

        .topics-item {
          @include mq(medium, min, true) {
            width: percentage(275/1160);
          }

          &:not(:nth-child(4n)) {
            @include mq(medium, min, true) {
              margin-right: percentage(20/1160);
            }
          }

          + .topics-item {
            @include mq() {
              margin-top: 10px;
            }
          }

          a {
            display: block;
            text-decoration: none;
            border: 1px solid $gray-dedede;

            @include mq(medium, min, true) {
              @include ease(opacity);
            }

            @include mq() {
              display: flex;
              padding: 15px;
            }

            &:hover {
              @include mq(medium, min, true) {
                opacity: 0.7;
              }
            }
          }

          .topics-image {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            @include mq(medium, min, true) {
              padding-top: percentage(145/275);
            }

            @include mq() {
              margin-right: 20px;
              padding-left: percentage(80/315);
              background-position: top center;
            }
          }

          .topics-detail {
            @include mq(medium, min, true) {
              padding: 20px 20px 30px;
            }

            @include mq() {
              flex: 1;
            }

            .detail-date {
              margin-bottom: 10px;

              .date,
              .category {
                display: inline-block;
              }

              .date {
                margin-right: 10px;
                font-family: $font-barlow;
                font-size: 1.6rem;
                font-weight: 500;
                color: $gray-808080;
              }

              .category {
                padding: 5px 8px;
                font-size: 1.2rem;
                line-height: 1;
                color: #2c86b8;
                border: 1px solid #2c86b8;
              }
            }

            .detail-title {
              margin-bottom: 10px;
              font-size: 1.4rem;
              font-weight: bold;
              line-height: 1.5;
            }

            .detail-group {
              font-size: 1.2rem;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
