@charset "utf-8";

.c-select {
  select {
    display: inline-block;
    width: 160px;
    padding: 10px 35px 10px 10px;
    font-family: inherit;
    font-size: 1.4rem;
    cursor: pointer;
    border: 1px solid $gray-dedede;
    border-radius: 4px;
    background: url(/assets/images/common/icons/select.svg) no-repeat top right;
    background-size: auto 100%;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  // type02（灰色 + 黒の▼アイコン）
  &--type02 {
    position: relative;

    @include mq(medium, min, true) {
      max-width: 596px;
    }

    select {
      display: block;
      width: 100%;
      padding: 12px 35px 12px 15px;
      font-family: inherit;
      font-size: 1.5rem;
      cursor: pointer;
      border: 1px solid $gray-dedede;
      border-radius: 4px;
      background-color: $gray-f5f5f5;
      appearance: none;

      &::-ms-expand {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 20px;
      right: 15px;
      width: 0;
      height: 0;
      pointer-events: none;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      border-color: $base-color transparent transparent transparent;
    }
  }
}
