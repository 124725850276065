@charset "utf-8";

#company-portfolio.company {
  .corporate-lower-links-wrapper {
    padding-bottom: 120px;

    @include mq() {
      padding-bottom: 75px;
    }
  }

  .company-nav {
    position: relative;
    margin: 60px auto;

    @include mq() {
      margin: 40px auto;
    }

    .container {
      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .nav-detail {
      position: relative;
      z-index: 2;

      @include mq(medium, min, true) {
        width: percentage(520/1160);
      }

      @include mq() {
        margin-bottom: 15px;
      }

      .detail-title {
        margin-bottom: 20px;
        font-size: 2.8rem;
        font-weight: bold;

        @include mq() {
          margin-bottom: 10px;
          font-size: 1.8rem;
        }
      }

      .detail-text {
        font-size: 1.6rem;

        @include mq() {
          font-size: 1.5rem;
        }
      }
    }

    .nav-image {
      position: relative;
      z-index: 1;

      @include mq(medium, min, true) {
        width: percentage(570/1160);
      }

      @include mq() {
        max-width: 285px;
        margin: 0 auto;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: 960px;
        height: 574px;
        transform: translate(-50%, -50%);
        background: url(/assets/images/company-portfolio/company/company_nav_bg.png) no-repeat center center;
        background-size: cover;

        @include mq() {
          width: 480px;
          height: 287px;
        }
      }

      .link {
        a {
          position: absolute;
          display: block;
          width: percentage(142/564);
          height: percentage(142/574);
          font-size: 0;
          border-radius: 50%;

          @include mq(medium, min, true) {
            @include ease(background-color);
          }

          &:hover {
            @include mq(medium, min, true) {
              background-color: rgba(#fff, 0.3);
            }
          }
        }

        .link-001 {
          right: percentage(96/574);
          bottom: percentage(44/574);
        }

        .link-002 {
          bottom: percentage(44/574);
          left: percentage(88/564);
        }

        .link-003 {
          top: percentage(157/574);
          right: percentage(19/564);
        }

        .link-004 {
          top: percentage(157/574);
          left: percentage(14/564);
        }

        .link-005 {
          top: percentage(15/574);
          left: percentage(208/564);
        }
      }
    }
  }

  .company-block {

    .company-box {
      padding: 60px 0;
      background-color: $gray-f5f5f5;

      @include mq() {
        padding: 30px 0;
      }

      &:nth-child(even) {
        background-color: $gray-efefef;
      }
    }

    .company-lead {
      margin-bottom: 48px;

      @include mq(medium, min, true) {
        display: flex;
        justify-content: space-between;
      }

      @include mq() {
        margin-bottom: 30px;
      }

      .lead-left {
        @include mq(medium, min, true) {
          width: percentage(620/1160);
        }

        @include mq() {
          margin-bottom: 20px;
        }

        .lead-title {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          @include mq() {
            margin-bottom: 20px;
          }

          .mark {
            width: 55px;
            flex-shrink: 0;
            margin-right: 20px;

            @include mq() {
              width: 45px;
            }
          }

          .text {
            flex: 1;
            font-weight: bold;

            em {
              font-size: 3.6rem;
              font-style: normal;
              line-height: 1;

              @include mq() {
                font-size: 3rem;
              }
            }

            span {
              display: block;
              font-size: 1.6rem;

              @include mq() {
                font-size: 1.4rem;
              }
            }
          }
        }

        .lead-text {
          font-size: 1.5rem;
        }
      }

      .lead-right {
        @include mq(medium, min, true) {
          flex-shrink: 0;
          width: percentage(460/1160);
        }

        .company-image {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 15px;
            left: 15px;
            width: 100%;
            height: 100%;
          }

          img {
            position: relative;
            z-index: 1;

            @include mq() {
              width: 100%;
            }
          }

          &.globalcommerce {
            &:after {
              background-color: #79bbe0;
            }
          }

          &.recommerce {
            &:after {
              background-color: #7f83ac;
            }
          }

          &.entertainment {
            &:after {
              background-color: #7abe9b;
            }
          }

          &.inbound {
            &:after {
              background-color: #f5db78;
            }
          }

          &.incubation {
            &:after {
              background-color: #eeb4b4;
            }
          }
        }
      }
    }

    .company-links {
      .link-item {
        padding: 30px;
        background-color: #fff;

        @include mq(medium, min, true) {
          display: flex;
          align-items: center;
        }

        @include mq() {
          padding: 15px;
        }

        + .link-item {
          margin-top: 10px;

          @include mq() {
            margin-top: 15px;
          }
        }

        .link-image {
          margin-right: 30px;

          @include mq(medium, min, true) {
            flex-shrink: 0;
            width: percentage(240/1100);
          }

          @include mq() {
            width: 240px;
            margin: 0 auto 10px;
          }
        }

        .link-detail {
          @include mq() {
            margin-bottom: 18px;
          }

          .link-title {
            margin-bottom: 8px;
            font-size: 2rem;
            font-weight: bold;

            @include mq() {
              margin-bottom: 15px;
              font-size: 1.8rem;
            }
          }

          .link-text {
            font-size: 1.5rem;
          }
        }

        .link-button {
          @include mq(medium, min, true) {
            flex-shrink: 0;
            width: 200px;
            margin-left: 30px;
          }

          @include mq() {
            display: flex;
            justify-content: space-between;
          }

          .c-button {
            @include mq() {
              width: calc(50% - 7px);
            }

            + .c-button {
              @include mq(medium, min, true) {
                margin-top: 15px;
              }
            }

            a {
              span {
                @include mq() {
                  font-size: 1.4rem;
                }

                @include mq(small) {
                  padding: 15px 30px 15px 15px;
                }
              }
            }
          }
        }
      }

      .about-area {
        margin-top: 40px;
        padding: 0;

        a {
          position: relative;
          display: block;
          padding: 40px;
          text-decoration: none;
          background: linear-gradient(to right, $accent-color, $accent-color02);

          @include mq(medium, min, true) {
            @include ease(opacity);
          }

          @include mq() {
            padding: 30px;
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            transform: translate(-50%, -50%);
            background-color: #fff;

            @include mq() {
              width: calc(100% - 4px);
              height: calc(100% - 4px);
            }
          }

          &:hover {
            @include mq(medium, min, true) {
              opacity: 0.7;
            }
          }
        }

        .about-detail {
          position: relative;
          z-index: 1;

          @include mq(medium, min, true) {
            display: flex;
            align-items: center;
          }

          .about-title {
            margin-right: 50px;
            padding-left: 70px;
            background: url(/assets/images/company-portfolio/company/about_icon.png) no-repeat center left;
            background-size: 48px auto;
            flex-shrink: 0;

            @include mq() {
              margin-bottom: 20px;
              padding-left: 50px;
              background-size: 38px auto;
            }

            &.en {
              display: flex;
              align-items: center;
              min-height: 56px;

              @include mq() {
                min-height: 45px;
              }
            }

            .ja,
            .en {
              display: block;
              font-weight: bold;
            }

            .ja {
              font-size: 2.4rem;

              @include mq() {
                font-size: 2rem;
                line-height: 1.2;
              }
            }

            .en {
              font-family: $font-barlow;
              font-size: 1.5rem;

              @include mq() {
                font-size: 1.4rem;
              }
            }
          }

          .about-text {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
