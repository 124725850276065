@charset "utf-8";

.c-newslist {
  .news-list {

    .news-item {
      position: relative;
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid $gray-dedede;

      &.pdf {
        padding-right: 100px;
      }

      a {
        text-decoration: none;

        .news-detail {
          .detail-text {
            text-decoration: none;
          }
        }

        &:hover {
          .news-detail {
            .detail-text {
              @include mq(medium, min, true) {
                text-decoration: underline;
                color: $accent-color03;
              }
            }
          }
        }
      }

      .news-detail {
        .detail-title {
          margin-bottom: 5px;

          span {
            display: inline-block;
            line-height: 1;
            color: $gray-808080;
          }

          .category {
            margin-left: 10px;
            padding: 4px 5px;
            font-size: 1.2rem;
            border: 1px solid;

            &.blue {
              color: #2c86b8;
              border-color: #2c86b8;
            }

            &.purple {
              color: #383b8f;
              border-color: #383b8f;
            }

            &.green {
              color: #318300;
              border-color: #318300;
            }

            &.red {
              color: #de294b;
              border-color: #de294b;
            }
          }

          .date {
            font-family: $font-barlow;
            font-size: 1.4rem;
            font-weight: 600;
          }
        }

        .detail-text {
          font-size: 1.5rem;
          font-weight: 500;

          @include mq(medium, min, true) {
            @include ease(color);
          }

          a {
            &:hover {
              @include mq(medium, min, true) {
                text-decoration: underline;
                color: $accent-color03;
              }
            }
          }
        }
      }

      .pdf-link {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 1.5rem;
        transform: translateY(-50%);

        a {
          display: inline-block;
          padding: 0;

          @include mq(medium, min, true) {
            @include ease(color);
          }

          &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 24px;
            margin-left: 5px;
            vertical-align: middle;
            background: url(/assets/images/common/icons/pdf.svg) no-repeat;
            background-size: auto 24px;
          }

          &:hover {
            @include mq(medium, min, true) {
              text-decoration: underline;
              color: $accent-color03;
            }
          }
        }
      }
    }

    // IR 適時開示
    .ir_item_code_tekijikaiji {
      .news-detail .detail-title .category {
        color: #318300;
        border-color: #318300;
      }
    }

    // IR 決算情報
    .ir_item_code_kessan {
      .news-detail .detail-title .category {
        color: #00869a;
        border-color: #00869a;
      }
    }

    // IR お知らせ
    .ir_item_code_info {
      .news-detail .detail-title .category {
        color: #de294b;
        border-color: #de294b;
      }
    }
  }
}
