@charset "utf-8";

#individual-investors {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/individual-investors/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/individual-investors/heading_bg_sp.jpg);
    }
  }

  .section-title {
    margin-bottom: 25px;

    @include mq() {
      margin-bottom: 20px;
    }
  }

  .section-text {
    margin-bottom: 40px;

    @include mq() {
      margin-bottom: 30px;
    }

    p {
      font-size: 1.8rem;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        font-size: 1.6rem;
      }
    }
  }

  .section-button {
    max-width: 300px;
    margin: 40px auto 0;

    @include mq() {
      margin-top: 20px;
    }

    .c-button {
      font-size: 1.6rem;
    }
  }

  .section-list {
    @include mq(medium, min, true) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .section-item {
      @include mq(medium, min, true) {
        width: percentage(360/1160);
        height: 232px;
      }

      &:not(:nth-child(3n)) {
        @include mq(medium, min, true) {
          margin-right: percentage(40/1160);
        }
      }

      + .section-item {
        @include mq() {
          margin-top: 15px;
        }
      }

      a {
        position: relative;
        display: flex;
        height: 100%;
        padding: 20px 40px;
        text-decoration: none;
        background-color: #fff;

        @include mq(medium, min, true) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        @include mq() {
          padding: 15px;
          align-items: center;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: linear-gradient(to right, $accent-color, $accent-color02);

          @include mq(medium, min, true) {
            @include ease(opacity);
          }
        }

        .icon,
        .text {
          position: relative;
          z-index: 1;
        }

        .icon {
          width: 80px;
          height: 80px;
          background-repeat: no-repeat;
          background-position: center left;
          background-size: auto 80px;
          flex-shrink: 0;

          @include mq(medium, min, true) {
            margin-bottom: 35px;
          }

          @include mq() {
            width: 56px;
            height: 56px;
            margin-right: 35px;
            background-size: auto 56px;
          }

          &.introduction_001 {
            background-image: url(/assets/images/investors/individual-investors/introduction_img_001.png);
          }

          &.introduction_002 {
            background-image: url(/assets/images/investors/individual-investors/introduction_img_002.png);
          }

          &.introduction_003 {
            background-image: url(/assets/images/investors/individual-investors/introduction_img_003.png);
          }

          &.work_001 {
            background-image: url(/assets/images/investors/individual-investors/work_img_001.png);
          }

          &.work_002 {
            background-image: url(/assets/images/investors/individual-investors/work_img_002.png);
          }

          &.work_003 {
            background-image: url(/assets/images/investors/individual-investors/work_img_003.png);
          }
        }

        .text {
          position: relative;
          display: inline-block;
          font-size: 2rem;
          font-weight: bold;
          line-height: 1.2;

          @include mq() {
            font-size: 1.8rem;
          }

          &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: -20px;
            width: 8px;
            height: 13px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 13px;
          }
        }

        &:hover {
          &:after {
            @include mq(medium, min, true) {
              opacity: 1;
            }
          }

          .text {
            @include mq(medium, min, true) {
              color: #fff;
            }

            &:before {
              @include mq(medium, min, true) {
                background-position: center right;
              }
            }
          }

          .icon {
            @include mq(medium, min, true) {
              background-position: center right;
            }
          }
        }
      }
    }
  }

  .description {
    background: url(/assets/images/investors/individual-investors/description_bg.jpg) no-repeat center center;
    background-size: cover;

    @include mq() {
      background-image: url(/assets/images/investors/individual-investors/description_bg_sp.jpg);
    }

    .description-list {
      @include mq(medium, min, true) {
        justify-content: space-between;
      }

      .description-item {
        @include mq(medium, min, true) {
          width: percentage(565/1160);
          height: 96px;
          margin-right: 0;
        }

        &:nth-child(n+3) {
          margin-top: 25px;
        }

        a {
          position: relative;
          padding: 30px 30px 30px 55px;
          border: none;

          @include mq(medium, min, true) {
            align-items: flex-start;
            justify-content: center;
          }

          @include mq() {
            padding: 30px 20px 30px 40px;
          }

          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
          }

          &:before {
            z-index: 1;
            bottom: 0;
            left: 24px;
            width: 8px;
            height: 13px;
            margin: auto 0;
            background: url(/assets/images/common/buttons/arrow.svg) no-repeat center left;
            background-size: auto 13px;
          }

          &:after {
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }

          span {
            position: relative;
            z-index: 1;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.2;

            @include mq() {
              font-size: 1.8rem;
            }
          }

          &:hover {
            &:before {
              @include mq(medium, min, true) {
                background-position: center right;
              }
            }

            &:after {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }

            span {
              @include mq(medium, min, true) {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
