@charset "utf-8";

#company-portfolio.investment-policy {

  .section-text {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.5rem;
  }

  .investmentpolicy-block {
    padding: 20px 0 55px;

    @include mq() {
      padding-bottom: 30px;
    }

    .investmentpolicy-title {
      margin-bottom: 15px;
    }
  }

  .area-block {
    padding: 50px 0 120px;

    @include mq() {
      padding-top: 30px;
      padding-bottom: 60px;
    }

    .area-title {
      margin-bottom: 40px;

      @include mq() {
        margin-bottom: 15px;
      }
    }

    .area-text {
      margin-bottom: 25px;

      @include mq() {
        margin-bottom: 45px;
      }

      p {
        + p {
          margin-top: 30px;
        }
      }
    }

    .area-map {
      max-width: 1080px;
      margin: 0 auto 25px;
    }

    .area-list {
      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
      }

      .area-item {
        @include mq(medium, min, true) {
          width: percentage(360/1160);
        }

        &:not(:nth-child(3n)) {
          @include mq(medium, min, true) {
            margin-right: percentage(40/1160);
          }
        }

        + .area-item {
          @include mq() {
            margin-top: 15px;
          }
        }
      }
    }

    .area-note {
      margin-top: 15px;
      font-size: 1.5rem;
    }

    .area-image {
      margin-top: 55px;

      @include mq() {
        margin-top: 30px;
      }
    }
  }
}
