@charset "utf-8";

#news-center {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .news-block {
    padding: 40px 0 120px;

    @include mq() {
      padding: 20px 0 60px;
    }

    .container {
      max-width: 1160px;
    }

    .news-banner {
      max-width: 640px;
      margin: 40px auto 0;

      @include mq() {
        margin-top: 20px;
      }
    }

    .news-tab {
      display: flex;
      margin: 0 auto 30px;

      @include mq() {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .news-item {
        width: 25%;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;

        @include mq() {
          width: calc(50% - 4px);
          font-size: 1.4rem;
        }

        @include mq(xxsmall) {
          font-size: 1.2rem;
        }

        &:not(:nth-child(2n)) {
          @include mq() {
            margin-right: 8px;
          }
        }

        &:nth-child(n+3) {
          @include mq() {
            margin-top: 8px;
          }
        }

        a {
          position: relative;
          display: block;
          padding: 15px 0;
          text-decoration: none;
          color: $gray-bfbfbf;
          background: $gray-f5f5f5;

          &:after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: linear-gradient(to right, $accent-color, $accent-color02);

            @include mq(medium, min, true) {
              @include ease(opacity);
            }
          }

          span {
            position: relative;
            z-index: 1;
          }

          &.is-active {
            color: #fff;

            &:after {
              opacity: 1;
            }

            &:before {
              @include mq(medium, min, true) {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -10px;
                border: 10px solid transparent;
                border-top: 12px solid #bd3327;
              }
            }
          }

          &:hover {
            @include mq(medium, min, true) {
              color: #fff;
            }

            &:after {
              @include mq(medium, min, true) {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .news-box {
      margin-top: 60px;

      @include mq() {
        margin-top: 30px;
      }
    }

    .news-select {
      margin-bottom: 20px;
      text-align: right;
    }
  }
}
