@charset "utf-8";

#privacypolicy {
  .c-heading-jumbotron-lower-main {
    background-image: url(/assets/images/corporate-information/heading_bg.png);

    @include mq() {
      background-image: url(/assets/images/corporate-information/heading_bg_sp.png);
    }
  }

  .privacypolicy-block {
    padding: 40px 0 120px;

    @include mq() {
      padding: 30px 0 60px;
    }

    .privacypolicy-box {
      + .privacypolicy-box {
        margin-top: 70px;
      }
    }

    .privacypolicy-title {
      margin-bottom: 35px;

      @include mq() {
        margin-bottom: 30px;
      }
    }

    .privacypolicy-text {
      margin-bottom: 40px;
      font-size: 1.5rem;
      line-height: 2;

      @include mq() {
        margin-bottom: 30px;
      }

      p {
        + p {
          margin-top: 30px;
        }
      }
    }

    .privacypolicy-name {
      font-size: 1.5rem;
      line-height: 2;
      text-align: right;
    }

    .privacypolicy-detail {
      + .privacypolicy-detail {
        margin-top: 60px;

        @include mq() {
          margin-top: 25px;
        }
      }

      .detail-title {
        margin-bottom: 25px;

        @include mq() {
          margin-bottom: 15px;
        }
      }

      .detail-text {
        font-size: 1.5rem;
        line-height: 1.8;

        > * {
          &:not(:last-child) {
            margin-bottom: 20px;

            @include mq() {
              margin-bottom: 15px;
            }
          }
        }
      }

      .detail-list {
        li {
          + li {
            @include mq(medium, min, true) {
              margin-top: 10px;
            }
          }
        }

        &.number {
          margin-left: 40px;

          > li {
            position: relative;
            list-style-type: none;
            counter-increment: cnt;

            &:before {
              content: "（" counter(cnt) "）";
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              width: 50px;
              margin-left: -50px;
              text-align: right;
            }

            ul {
              li {
                margin-top: 30px;
              }
            }
          }
        }

        &.number02 {
          margin-left: 35px;

          > li {
            &:before {
              content: counter(cnt) "）";
              width: 35px;
              margin-left: -35px;
            }
          }
        }
      }
    }
  }
}
