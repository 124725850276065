@charset "utf-8";

.c-table {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.4;

  .table-title {
    border-bottom: 2px solid $base-color;
    background-color: #fff;

    .title {
      height: 56px;
      padding: 5px 20px;
      font-weight: 500;

      @include mq() {
        padding: 5px 10px;
      }
    }
  }

  .table-text {
    border-bottom: 1px solid $gray-dedede;

    &:first-child {
      border-top: 1px solid $gray-dedede;
    }

    &:nth-child(odd) {
      background-color: $gray-f5f5f5;
    }

    .title,
    .text {
      padding: 20px;

      @include mq() {
        padding: 20px 10px;
      }
    }
  }

  sup {
    font-size: 60%;
    vertical-align: bottom;
    color: inherit;
  }

  // type02（行タイトルあり）
  &--type02 {
    .table-text {
      .title {
        background-color: $gray-f5f5f5;
      }

      .text {
        background-color: #fff;
      }
    }
  }
}
