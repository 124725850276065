@charset "utf-8";

#ir-mail-magazine {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-information/ir-mail-magazine/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-information/ir-mail-magazine/heading_bg_sp.jpg);
    }
  }

  .magazine-text {
    margin-bottom: 40px;
    font-size: 1.5rem;

    @include mq() {
      margin-bottom: 48px;
    }
  }

  .magazine-attention {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  .magazine-form {
    .form-detail {
      position: relative;
      padding: 15px 0;
      border-bottom: 1px solid $gray-dedede;

      @include mq() {
        padding: 10px 0 12px;
      }

      &:after {
        @include mq(medium, min, true) {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 360px;
          height: 100%;
          background-color: $gray-f5f5f5;
        }
      }

      &:first-of-type {
        border-top: 1px solid $gray-dedede;
      }

      .detail-item {
        position: relative;
        z-index: 1;
        font-size: 1.5rem;

        @include mq(medium, min, true) {
          display: flex;
          align-items: center;
        }

        + .detail-item {
          margin-top: 15px;

          @include mq() {
            margin-top: 5px;
          }
        }
      }

      .detail-title {
        @include mq(medium, min, true) {
          width: 360px;
          padding: 0 30px;
          flex-shrink: 0;
          text-align: right;
        }

        @include mq() {
          margin-bottom: 5px;
        }

        span {
          position: relative;
          padding-right: 12px;

          .sup {
            position: absolute;
            top: -8px;
            right: -5px;
          }
        }
      }

      .detail-text {
        @include mq(medium, min, true) {
          flex: 1;
          padding: 0 30px;
        }

        .c-radio {
          padding: 10px 0;

          @include mq() {
            padding: 8px 0;
          }
        }
      }
    }

    .form-button {
      margin: 60px auto 0;

      @include mq(medium, min, true) {
        max-width: 450px;
      }

      @include mq() {
        margin-top: 45px;
      }
    }
  }

  .sup {
    font-size: 180%;
    font-style: normal;
    color: $accent-color;
  }
}
