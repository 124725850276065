@charset "utf-8";

.stock-highlights-cont {

  img {
    @include mq() {
      width: 100%;
    }
  }

  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/stock-highlights/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/stock-highlights/heading_bg_sp.jpg);
    }
  }

  .highlights-category {
    display: flex;
    margin-top: -7px;
    margin-bottom: 60px;
    flex-wrap: wrap;

    @include mq() {
      margin-bottom: 30px;
    }

    .category-item {
      margin: 7px 0;
      font-size: 1.5rem;
      line-height: 1;

      &:not(:last-child) {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid $gray-bfbfbf;
      }

      a {
        text-decoration: none;
        color: $gray-bfbfbf;

        &:hover {
          @include mq(medium, min, true) {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .highlights-title {
    margin-bottom: 20px;

    @include mq() {
      margin-bottom: 15px;
    }
  }

  .highlights-text {
    margin-bottom: 25px;

    @include mq() {
      margin-bottom: 8px;
      font-size: 1.3rem;
    }
  }

  .highlights-list {
    margin-bottom: 35px;

    @include mq(medium, min, true) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include mq() {
      margin-bottom: 30px;
    }

    .highlights-item {
      @include mq(medium, min, true) {
        width: calc(50% - 60px);

        @include mq(large) {
          width: calc(50% - 30px);
        }
      }

      &:nth-child(n+3) {
        @include mq(medium, min, true) {
          margin-top: 55px;
        }
      }

      + .highlights-item {
        @include mq() {
          margin-top: 30px;
        }
      }
    }
  }

  .highlights-note {
    font-size: 1.2rem;
    color: $gray-666;

    + .highlights-note {
      margin-top: 25px;
    }

    .note-text {
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  .highlights-image {
    cursor: pointer;
  }
}
