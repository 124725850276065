@charset "utf-8";

.information-cont {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-information/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-information/heading_bg_sp.jpg);
    }
  }

  .section {
    padding-top: 0;
    padding-bottom: 70px;

    @include mq() {
      padding-bottom: 48px;
    }
  }

  .information-title {
    margin-bottom: 25px;

    @include mq() {
      margin-bottom: 18px;
    }
  }

  .information-text {
    font-size: 1.5rem;

    p {
      + p {
        margin-top: 25px;
      }
    }
  }
}
