@charset "utf-8";

#corporate-information.message {
  .ceo-message {
    padding: 100px 0 120px;

    @include mq() {
      padding: 50px 0 70px;
    }

    .ceo-message-image,
    .ceo-message-textarea {
      max-width: 850px;
      margin-right: auto;
      margin-left: auto;
    }

    .ceo-message-textarea {
      margin-top: 60px;

      @include mq() {
        margin-top: 30px;
      }
    }

    .ceo-message-title {
      margin-bottom: 40px;
      font-size: 3rem;
      font-weight: bold;

      @include mq(medium, min, true) {
        text-align: center;
      }

      @include mq() {
        margin-top: 30px;
        margin-bottom: 25px;
        font-size: 2.2rem;
      }
    }

    .ceo-message-text {
      font-size: 1.6rem;
      line-height: 2;

      @include mq() {
        font-size: 1.5rem;
      }
    }

    .ceo-message-sign {
      margin-top: 30px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2;

      @include mq(medium, min, true) {
        text-align: right;
      }

      @include mq() {
        font-size: 1.5rem;
      }
    }
  }
}
