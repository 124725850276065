@charset "utf-8";

.l-column　{
  padding-top: 40px;
  padding-bottom: 120px;

  @include mq() {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}

.l-column--col2 {
  @include mq(medium, min, true) {
    display: flex;
  }

  .col2-main {
    @include mq(medium, min, true) {
      flex: 1;
      min-width: 0;
    }

    @include mq() {
      margin-bottom: 35px;
    }
  }

  .col2-sub {
    width: 330px;
    margin-left: 50px;

    @include mq(medium, min, true) {
      flex-shrink: 0;
    }

    @include mq(large) {
      width: 290px;
      margin-left: 20px;
    }

    @include mq() {
      width: 100%;
      margin-left: 0;
    }
  }
}
