@charset "utf-8";

#financial {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/policy/financial/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/policy/financial/heading_bg_sp.jpg);
    }
  }

  .indicators-block {
    padding: 60px 0 140px;

    @include mq() {
      padding: 30px 0 65px;
    }

    .indicators-title {
      margin-bottom: 20px;

      @include mq() {
        margin-bottom: 10px;
      }
    }

    .indicators-list {
      margin-bottom: 30px;

      @include mq(medium, min, true) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include mq() {
        margin-bottom: 20px;
      }

      .indicators-item {
        @include mq(medium, min, true) {
          width: calc(50% - 60px);

          @include mq(large) {
            width: calc(50% - 30px);
          }
        }

        &:nth-child(n+3) {
          @include mq(medium, min, true) {
            margin-top: 80px;
          }
        }

        + .indicators-item {
          @include mq() {
            margin-top: 30px;
          }
        }
      }
    }

    .indicators-table {
      .table-item {
        + .table-item {
          margin-top: 40px;

          @include mq() {
            margin-top: 20px;
          }
        }
      }

      .unit {
        margin-bottom: 15px;
        font-size: 1.5rem;
        text-align: right;

        @include mq() {
          margin-bottom: 10px;
        }
      }

      .c-table {
        @include mq() {
          display: block;
        }

        tbody,
        tr {
          background-color: transparent;

          @include mq() {
            display: block;
            border: none;
          }
        }

        th,
        td {
          @include mq(medium, min, true) {
            border-left: 1px solid $gray-dedede;
          }

          @include mq() {
            display: block;
            width: 100%;
          }

          &:last-child {
            @include mq(medium, min, true) {
              border-right: 1px solid $gray-dedede;
            }
          }
        }

        td {
          @include mq() {
            border: 1px solid $gray-dedede;
          }

          .title {
            justify-content: center;
            text-align: center;
          }

          + td {
            margin-top: 10px;
          }
        }

        .title,
        .text {
          min-height: 64px;

          @include mq() {
            min-height: 46px;
          }
        }

        .title {
          display: flex;
          align-items: center;
          padding-top: 0;
          padding-bottom: 0;
          font-weight: 500;

          @include mq() {
            color: #fff;
            background-color: $gray-666;
          }

          + .title {
            border-top: 1px solid $gray-dedede;
          }
        }

        .text {
          border-top: 1px solid $gray-dedede;

          @include mq() {
            padding: 0;
          }

          p {
            @include mq() {
              padding: 15px 25px;
            }

            &:not(.sub) {
              text-align: right;

              @include mq() {
                text-align: left;
              }
            }
          }

          .sub {
            border-bottom: 1px solid $gray-dedede;
            background-color: $gray-f5f5f5;

            @include mq(medium, min, true) {
              display: none;
            }
          }
        }
      }
    }

    .indicators-note {
      margin-top: 30px;
      font-size: 1.2rem;
      color: $gray-666;

      @include mq() {
        margin-top: 10px;
      }

      .note-text {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
}
