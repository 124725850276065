@charset "utf-8";

#ir-news {
  .c-heading-jumbotron {
    background-image: url(/assets/images/investors/ir-news/heading_bg.jpg);

    @include mq() {
      background-image: url(/assets/images/investors/ir-news/heading_bg_sp.jpg);
    }
  }

  .news-text {
    margin-bottom: 15px;
    font-size: 1.5rem;

    @include mq() {
      margin-bottom: 30px;
    }
  }

  .news-category {
    display: flex;
    flex-wrap: wrap;

    .category-item {
      margin: 7px 15px 7px 0;
      padding-right: 15px;
      line-height: 1;

      &:not(:last-child) {
        border-right: 1px solid $gray-bfbfbf;
      }

      &:not(.is-active) {
        cursor: pointer;
        color: $gray-bfbfbf;

        &:hover {
          @include mq(medium, min, true) {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .news-select {
    margin-bottom: 50px;
    text-align: right;
  }

  .tab-cont {
    transition: all 0.3s;

    &:not(.is-active) {
      overflow: hidden;
      height: 0;
      transform: translateY(40px);
      opacity: 0;
    }
  }

  .c-newslist {
    margin-bottom: 30px;

    @include mq() {
      margin-bottom: 20px;
    }
  }
}
